import React from 'react'
import { getCliente, getEstacionamento, getRevenda, isAdministrador, isOwner } from '../../config/auth'
import { NavBar } from '../../components/navbar'
import { Tabs, Tab } from 'react-bootstrap';
import { generateUUIDV7, generateXAPIKey, goToNewWindow, queryString, reloadWithAlert, setPathWindow, takeIfIsNotNullOrEmpty, toastSuccess, toastWarning } from '../../shared/utils'
import { Loading } from '../../components/common/commons'
import NumberFormat from 'react-number-format';
import firebase from '../../config/firebase'
import 'firebase/storage'
import { sendClickButton } from '../../shared/analytics';
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import { getPrismSettings, resetPrismSettings, saveNewPrismSettings } from '../../store/collections/prismWorker';
import moment from 'moment';
import Select from 'react-select';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getFormasPagamentosAtivas } from '../../store/collections/formaPagamentoWorker';
import { fetchFunction } from '../../shared/function-request';
import Tooltip from '@mui/material/Tooltip';
import { getURLFromURI, uploadParkPrinterImage } from '../../store/filesWorker';
import { getAllParametersByClienteId, getParameterByParkId, getParameterByXAPIKey, updateParameterById } from '../../store/collections/parameterWorker';
import { MovementCategoriesCashierModal } from '../cashier/components';
import { AlertDanger, AlertInfo, AlertSecondary, AlertSuccess, AlertWarning } from '../../shared/alert-manager';
import { equipmentManagerReloadAllEquipments } from '../../managers/equipmentManager';
import { getParkById } from '../../store/collections/parkWorker';
import { getRevendaById } from '../../store/collections/revendaWorker';
import { activeCashier } from '../../store/collections/cashierWorker';
import { updateDoc } from '../../store/collections/collectionBaseWorker';
import { Collection } from '../../shared/constants';

const initialState = {
    id: '',
    key: 'caixa',
    clienteId: getCliente().id,
    estacionamentoId: getEstacionamento()?.id,
    trocoInicialCaixa: false,
    prismaEstacionamento: false,
    typePrismaEstacionamento: "open_field",
    maxSequencePrismaEstacionamento: "0",
    sequencePrismaEstacionamento: "0",
    setorEstacionamento: false,
    observacaoEstacionamento: false,
    habilitarCampoContato: false,
    fecharCaixaParcial: false,
    fecharCaixaCego: false,
    habilitarCobrancaMensalista: false,
    habilitarCartaoParaAcesso: false,
    habilitarTicketAvulsoParaMensalista: false,
    habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista: false,
    habilitarTarifaAposVencimento: false,
    tarifaAposVencimenoDias: '1',
    habilitarMensagemInadimplente: false,
    mensagemMensalistaInadimplentes: "",
    habilitarRotacionarVagaMensalista: true,
    habilitarDescontoAntesDoVencimento: false,
    habilitarTicketResumido: false,
    descontoAntesDoVencimentoPorcentagem: "0.0",
    descontoAntesDoVencimentoDias: '1',
    habilitarAcrecimoAposVencimento: false,
    acrecimoAposVencimentoPorcentagem: "0.0",
    acrecimoAposVencimentoDias: '1',
    habilitarEdicaoAvaliacaoInternaNoTicket: false,
    habilitarEdicaoPlacaNoTicket: false,
    habilitarEdicaoAvaliacaoExternaNoTicket: false,
    habilitarObrigatoriedadeAvaliacaoExterna: false,
    habilitarTipoDeAvariaAvaliacaoExterna: false,
    habilitarImprimirTabelaPrecoNoTicket: false,
    habilitarFecharCaixaPorPeriodo: false,
    periodoFecharCaixaApartirDeUmHorarioEmHoras: "00:00",
    habilitarObrigatoriedadeModeloCorDoVeiculo: true,
    habilitarObrigatoriedadeModeloCorDoVeiculoPorFoto: true,
    habilitarObrigatoriedadeTabelaDePrecoPorVeiculo: true,
    habilitarMensagemQuandoSelecionadaNoTicket: false,
    privadoHabilitarPermitirEnviarSMS: false,
    privadoHabilitarPermitirEnviarWhatsApp: false,
    tituloQuandoSelecionadaNoTicket: '',
    tituloQuandoSelecionadaNoTicketTamanho: 'normal',
    tamanhoDoNomeDoEstacionamento: 'normal',
    mensagemQuandoSelecionadaNoTicket: '',
    mensagemQuandoSelecionadaNoTicketLocal: 'comeco',
    habilitarMensagemImpressaNoTicket: false,
    habilitarRetiradaDeCaixaAcima: false,
    habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo: false,
    habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo: false,
    habilitarImprimirObservacaoRetirada: false,
    habilitarTicketBatch: false,
    imprimirTicketNoFormato: 'QRCode',
    datahabilitarNumeroImpressoNoTicket: true,
    habilitarPagamentosDeMultiplasMensalidadesDoMensalista: false,
    habilitarPagamentosDeMultiplasMensalidadesDoMensalistaMeses: "1",
    mensagemLancamentoTicketLocal: 'comeco',
    typeKeyboard: "smart",
    mensagemLancamentoTicket: '',
    periodoFecharCaixaApartirDeUmHorario: new moment(new Date()),
    habilitarDescontoManual: false,
    habilitarSemParar: false,
    habilitarObrigatoriedadeModeloCorDoVeiculoRapida: true,
    codigoEstabelecimentoSemParar: "",
    hashEstabelecimentoSemParar: "",
    enviarTicketSemParar: false,
    habilitarDataSaida: false,
    habilitarDataEntrada: false,
    habilitarDescontoManualMensalista: false,
    printCustomImage: false,
    habilitarPrintParkImage: false,
    printWebSite: true,
    privadoHabilitarNovaCameraAndroid: false,
    algoritmoDeImpressao: "PRINT_BITMAP",
    habilitarMensalistaImpressaoTicketEntrada: true,
    habilitarOnlineCalculation: false,
    habilitarAlterarOperadorAoAbrirCaixa: false,
    habilitarImprimirTituloDocumentoNaoFiscal: true,
    habilitarProdutosConsumidosNaProximaFatura: false,
    habilitarCredenciadosCadastrarViaLink: false,
    habilitarCompartilhamentoDeMensalistasNaRede: false,
    habilitarCancela: false,
    isLoading: true,
    X_API_KEY: '',
    cancelaNotificarStatus: [],
    cancelaURLStatus: '',
    cancelaWebHook: '',
    habilitarImpressaoDadosEstabelecimentoNome: true,
    habilitarImpressaoDadosEstabelecimentoDocumento: true,
    habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento: true,
    habilitarImpressaoDadosEstabelecimentoEndereco: true,
    habilitarImpressaoDadosEstabelecimentoCelular: true,
    habilitarZerarPrisma: false,
    allStatus: [{ label: "Estacionado", value: "Estacionado" },
    { label: "Pago e Estacionado", value: "Pago e Estacionado" },
    { label: "Pago e Entregue", value: "Pago e Entregue" },
    { label: "Cancelado", value: "Cancelado" },
    { label: "Pago e Aguardando", value: "Pago e Aguardando" },
    { label: "Mensalistas", value: "Mensalistas" },
    { label: "Credenciados", value: "Credenciados" }],
    timezones: [],
    timezone: '',
    habilitarAutoAtendimento: false,
    habilitarPagamentoTicketOnline: false,
    habilitarPagamentoMensalistasOnline: false,
    habilitarEmissaoNotaParaTodosOsPagamentos: false,
    habilitarEmissaoNotaPorTipo: "TOTAL",
    habilitarSeniorERP: false,
    habilitarTelaPrismaLancamentoRapido: false,
    habilitarZerarPrismaValorPadrao: false,
    habilitarValorTotalNoTicketDeCaixasAnteriores: false,
    habilitarImpressaoDeRelatorioNoFechamentoDoCaixa: true,
    habilitarMovimentacoesDeCaixa: true,
    typeCashierOpened: "park",
    habilitarMostrarCoresDosVeiculosNaListagem: false,
    habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem: false,
    habilitarMostrarDetalhesDoTicketNoPagamento: false,
    habilitarCalculadoraAoSelecionarDinheiro: false,
    habilitarAssociacaoDeConvenioNaformaDePagamento: false,
    logoParkPrinterExample: "https://dummyimage.com/200x48/ececec/000",
    habilitarParcelamentoEmPagamentoDeTicket: false,
    habilitarParcelamentoEmPagamentoDeTicketAPartirDoValor: 0,
    habilitarParcelamentoEmPagamentoDeTicketComJurosPor: "BOTH",
    habilitarParcelamentoEmPagamentoDeTicketNoMaximoDeParcelas: 0,
    habilitarParcelamentoEmPagamentoDeMensalista: false,
    habilitarParcelamentoEmPagamentoDeMensalistaAPartirDoValor: 0,
    habilitarParcelamentoEmPagamentoDeMensalistaComJurosPor: "BOTH",
    habilitarParcelamentoEmPagamentoDeMensalistaNoMaximoDeParcelas: 0,
    habilitarPatioAoVivo: false,
    habilitarPatioAoVivoEmHoras: "00:00",
    logoParkPrinter: null,
    logoParkPrinterFile: null,
    habilitarLogsAplicativo: false,
    habilitarBuscaDeMarcaEModeloOnline: false,
    permitirSaidaTemporariaMensalista: false,
    habilitarControlarVagas: false,
    habilitarControlarVagasQuantidade: 0,
    habilitarControlarVagasBloquearEntrada: false,
    habilitarEmergenciaNasCancelas: false,
    habilitarReusoDeTicketImpressoNasCancelas: false,
    habilitarReusoDeTicketImpressoNasCancelasAux: false,
    pagamentoOnlineGatewayName: "Safe2Pay",
    pagamentoOnlineGatewayToken: "",
    pagamentoOnlineShipayAccessKey: "",
    pagamentoOnlineShipaySecretKey: "",
    pagamentoOnlineShipayClientId: "",
    habilitarPagamentoMensalistasOnline: false,
    habilitarPagamentoTicketOnline: false,
    habilitarObrigacaoParaAtualizarOsParametros: true,
    habilitarOCRNaFotoDoVeiculo: false,
    bloquearEntradaAvulso: false,
    quantidadeDeFotosAvaliacaoExterna: 5,
    habilitarF360ERP: false,
    identificadorUnicoServicoF360: null,
    documentF360: null,
    habilitarConectCar: false,
    habilitarVeloe: false,
    codigoEstabelecimentoConectCar: "",
    codigoEstabelecimentoVeloe: "",
    habilitarTotem: false,
    habilitarTotemCompraDeProdutos: false,
    habilitarTotemPagamentoDeTicket: false,
    habilitarTotemPagamentoDeMensalista: false,
    habilitarPDVZanthus: false,
    isShowCamposContatosModal: false,
    habilitarCampoContatoNome: false,
    habilitarCampoContatoNomeObrigatorio: false,
    habilitarCampoContatoNomeEmpresa: false,
    habilitarCampoContatoNomeEmpresaObrigatorio: false,
    habilitarCampoContatoCelular: false,
    habilitarCampoContatoCelularObrigatorio: false,
    habilitarCampoContatoEmail: false,
    habilitarCampoContatoEmailObrigatorio: false,
    habilitarCampoContatoTelefone: false,
    habilitarCampoContatoTelefoneObrigatorio: false,
    habilitarCampoContatoDocumento: false,
    habilitarCampoContatoDocumentoObrigatorio: false,
    habilitarCampoContatoRG: false,
    habilitarCampoContatoRGObrigatorio: false,
    habilitarCampoContatoDepartamento: false,
    habilitarCampoContatoDepartamentoObrigatorio: false,
    habilitarCampoContatoObservacao: false,
    habilitarCampoContatoObservacaoObrigatorio: false,
    habilitarCampoContatoMarcaModelo: false,
    habilitarCampoContatoMarcaModeloObrigatorio: false,
    habilitarCampoContatoFotoMotorista: false,
    habilitarCampoContatoFotoMotoristaObrigatorio: false,
    habilitarCampoContatoFotoHabilitacao: false,
    habilitarCampoContatoFotoHabilitacaoObrigatorio: false
}

class ListarParametros extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState
    }

    componentDidMount = async () => {
        const tab = queryString("tab")
        this.changeTab(tab ?? "caixa")
        await this.loadParameters()
    }

    loadParameters = async () => {
        const results = await firebase.firestore().collection('parametros').where('estacionamentoId', '==', this.state.estacionamentoId).get()
        const data = results.docs[0].data()
        this.setState({ ...data, id: results.docs[0].id })
        this.setState({ habilitarDescontoManual: data.habilitarDescontoManual ?? false })
        this.setState({ habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo: data.habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo ?? false })
        this.setState({ habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo: data.habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo ?? false })
        this.setState({ habilitarObrigatoriedadeTabelaDePrecoPorVeiculo: data.habilitarObrigatoriedadeTabelaDePrecoPorVeiculo ?? false })
        this.setState({ imprimirTicketNoFormato: data.imprimirTicketNoFormato ?? "QRCode" })
        this.setState({ datahabilitarNumeroImpressoNoTicket: data.datahabilitarNumeroImpressoNoTicket ?? true })
        this.setState({ periodoFecharCaixaApartirDeUmHorario: data.periodoFecharCaixaApartirDeUmHorario ?? new moment(new Date()) })
        this.setState({ periodoFecharCaixaApartirDeUmHorarioEmHoras: data.periodoFecharCaixaApartirDeUmHorarioEmHoras ?? "00:00" })
        this.setState({ tamanhoDoNomeDoEstacionamento: data.tamanhoDoNomeDoEstacionamento ?? "normal" })
        this.setState({ habilitarSemParar: data.habilitarSemParar ?? false })
        this.setState({ codigoEstabelecimentoSemParar: data.codigoEstabelecimentoSemParar ?? "" })
        this.setState({ hashEstabelecimentoSemParar: data.hashEstabelecimentoSemParar ?? "" })
        this.setState({ enviarTicketSemParar: data.enviarTicketSemParar ?? false })
        this.setState({ habilitarDataSaida: data.habilitarDataSaida ?? false })
        this.setState({ habilitarTicketBatch: data.habilitarTicketBatch ?? false })
        this.setState({ habilitarObrigatoriedadeModeloCorDoVeiculoRapida: data.habilitarObrigatoriedadeModeloCorDoVeiculoRapida ?? true })
        this.setState({ habilitarMensalistaImpressaoTicketEntrada: data.habilitarMensalistaImpressaoTicketEntrada ?? true })
        this.setState({ habilitarDescontoManualMensalista: data.habilitarDescontoManualMensalista ?? false })
        this.setState({ printCustomImage: data.printCustomImage ?? false })
        this.setState({ printWebSite: data.printWebSite ?? true })
        this.setState({ privadoHabilitarNovaCameraAndroid: data.privadoHabilitarNovaCameraAndroid ?? false })
        this.setState({ algoritmoDeImpressao: data.algoritmoDeImpressao ?? "PRINT_BITMAP" })
        this.setState({ habilitarAlterarOperadorAoAbrirCaixa: data.habilitarAlterarOperadorAoAbrirCaixa ?? false })
        this.setState({ habilitarRotacionarVagaMensalista: data.habilitarRotacionarVagaMensalista ?? true })
        this.setState({ habilitarDataEntrada: data.habilitarDataEntrada ?? false })
        this.setState({ habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista: data.habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista ?? false })
        this.setState({ habilitarPagamentosDeMultiplasMensalidadesDoMensalista: data.habilitarPagamentosDeMultiplasMensalidadesDoMensalista ?? false })
        this.setState({ habilitarPagamentosDeMultiplasMensalidadesDoMensalistaMeses: data.habilitarPagamentosDeMultiplasMensalidadesDoMensalistaMeses ?? "1" })
        this.setState({ typeKeyboard: data.typeKeyboard ?? "smart" })
        this.setState({ timezone: data.timezone ?? '' })
        this.setState({ habilitarImprimirObservacaoRetirada: data.habilitarImprimirObservacaoRetirada ?? false })
        this.setState({ observacaoEstacionamento: data.observacaoEstacionamento ?? false })
        this.setState({ habilitarCampoContato: data.habilitarCampoContato ?? false })
        this.setState({ habilitarCampoContatoNome: data.habilitarCampoContatoNome ?? data.habilitarCampoContato ?? false })
        this.setState({ habilitarCampoContatoNomeObrigatorio: data.habilitarCampoContatoNomeObrigatorio ?? false })
        this.setState({ habilitarCampoContatoNomeEmpresa: data.habilitarCampoContatoNomeEmpresa ?? false })
        this.setState({ habilitarCampoContatoNomeEmpresaObrigatorio: data.habilitarCampoContatoNomeEmpresaObrigatorio ?? false })
        this.setState({ habilitarCampoContatoCelular: data.habilitarCampoContatoCelular ?? data.habilitarCampoContato ?? false })
        this.setState({ habilitarCampoContatoCelularObrigatorio: data.habilitarCampoContatoCelularObrigatorio ?? false })
        this.setState({ habilitarCampoContatoEmail: data.habilitarCampoContatoEmail ?? false })
        this.setState({ habilitarCampoContatoEmailObrigatorio: data.habilitarCampoContatoEmailObrigatorio ?? false })
        this.setState({ habilitarCampoContatoTelefone: data.habilitarCampoContatoTelefone ?? false })
        this.setState({ habilitarCampoContatoTelefoneObrigatorio: data.habilitarCampoContatoTelefoneObrigatorio ?? false })
        this.setState({ habilitarCampoContatoDocumento: data.habilitarCampoContatoDocumento ?? false })
        this.setState({ habilitarCampoContatoDocumentoObrigatorio: data.habilitarCampoContatoDocumentoObrigatorio ?? false })
        this.setState({ habilitarCampoContatoRG: data.habilitarCampoContatoRG ?? false })
        this.setState({ habilitarCampoContatoRGObrigatorio: data.habilitarCampoContatoRGObrigatorio ?? false })
        this.setState({ habilitarCampoContatoDepartamento: data.habilitarCampoContatoDepartamento ?? false })
        this.setState({ habilitarCampoContatoDepartamentoObrigatorio: data.habilitarCampoContatoDepartamentoObrigatorio ?? false })
        this.setState({ habilitarCampoContatoObservacao: data.habilitarCampoContatoObservacao ?? false })
        this.setState({ habilitarCampoContatoObservacaoObrigatorio: data.habilitarCampoContatoObservacaoObrigatorio ?? false })
        this.setState({ habilitarCampoContatoMarcaModelo: data.habilitarCampoContatoMarcaModelo ?? false })
        this.setState({ habilitarCampoContatoMarcaModeloObrigatorio: data.habilitarCampoContatoMarcaModeloObrigatorio ?? false })
        this.setState({ habilitarCampoContatoFotoMotorista: data.habilitarCampoContatoFotoMotorista ?? false })
        this.setState({ habilitarCampoContatoFotoMotoristaObrigatorio: data.habilitarCampoContatoFotoMotoristaObrigatorio ?? false })
        this.setState({ habilitarCampoContatoFotoHabilitacao: data.habilitarCampoContatoFotoHabilitacao ?? false })
        this.setState({ habilitarCampoContatoFotoHabilitacaoObrigatorio: data.habilitarCampoContatoFotoHabilitacaoObrigatorio ?? false })
        this.setState({ habilitarImprimirTituloDocumentoNaoFiscal: data.habilitarImprimirTituloDocumentoNaoFiscal ?? true })
        this.setState({ habilitarProdutosConsumidosNaProximaFatura: data.habilitarProdutosConsumidosNaProximaFatura ?? false })
        this.setState({ habilitarCredenciadosCadastrarViaLink: data.habilitarCredenciadosCadastrarViaLink ?? false })
        this.setState({ habilitarCompartilhamentoDeMensalistasNaRede: data.habilitarCompartilhamentoDeMensalistasNaRede ?? false })
        this.setState({ habilitarCancela: data.habilitarCancela ?? false })
        this.setState({ cancelaURLStatus: data.cancelaURLStatus ?? '' })
        this.setState({ cancelaWebHook: data.cancelaWebHook ?? '' })
        this.setState({ habilitarImpressaoDadosEstabelecimentoNome: data.habilitarImpressaoDadosEstabelecimentoNome ?? true })
        this.setState({ habilitarImpressaoDadosEstabelecimentoDocumento: data.habilitarImpressaoDadosEstabelecimentoDocumento ?? true })
        this.setState({ habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento: data.habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento ?? true })
        this.setState({ habilitarImpressaoDadosEstabelecimentoEndereco: data.habilitarImpressaoDadosEstabelecimentoEndereco ?? true })
        this.setState({ habilitarImpressaoDadosEstabelecimentoCelular: data.habilitarImpressaoDadosEstabelecimentoCelular ?? true })
        this.setState({ cancelaNotificarStatus: (data.cancelaNotificarStatus ?? []).map(e => ({ label: e, value: e })) })
        this.setState({ habilitarZerarPrisma: data.habilitarZerarPrisma ?? false })
        this.setState({ habilitarAutoAtendimento: data.habilitarAutoAtendimento ?? false })
        this.setState({ habilitarOnlineCalculation: data.habilitarOnlineCalculation ?? false })
        this.setState({ habilitarPagamentoTicketOnline: data.habilitarPagamentoTicketOnline ?? false })
        this.setState({ habilitarPagamentoMensalistasOnline: data.habilitarPagamentoMensalistasOnline ?? false })
        this.setState({ habilitarObrigacaoParaAtualizarOsParametros: data.habilitarObrigacaoParaAtualizarOsParametros ?? true })
        this.setState({ habilitarOCRNaFotoDoVeiculo: data.habilitarOCRNaFotoDoVeiculo ?? false })
        this.setState({ bloquearEntradaAvulso: data.bloquearEntradaAvulso ?? false })
        this.setState({ quantidadeDeFotosAvaliacaoExterna: data.quantidadeDeFotosAvaliacaoExterna ?? 5 })
        this.setState({ habilitarTelaPrismaLancamentoRapido: data.habilitarTelaPrismaLancamentoRapido ?? false })
        this.setState({ habilitarZerarPrismaValorPadrao: data.habilitarZerarPrismaValorPadrao ?? false })
        this.setState({ habilitarValorTotalNoTicketDeCaixasAnteriores: data.habilitarValorTotalNoTicketDeCaixasAnteriores ?? false })
        this.setState({ habilitarImpressaoDeRelatorioNoFechamentoDoCaixa: data.habilitarImpressaoDeRelatorioNoFechamentoDoCaixa ?? true })
        this.setState({ habilitarMovimentacoesDeCaixa: data.habilitarMovimentacoesDeCaixa ?? true })
        this.setState({ typeCashierOpened: data.typeCashierOpened ?? "park" })
        this.setState({ habilitarMostrarCoresDosVeiculosNaListagem: data.habilitarMostrarCoresDosVeiculosNaListagem ?? false })
        this.setState({ habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem: data.habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem ?? true })
        this.setState({ habilitarCalculadoraAoSelecionarDinheiro: data.habilitarCalculadoraAoSelecionarDinheiro ?? false })
        this.setState({ habilitarAssociacaoDeConvenioNaformaDePagamento: data.habilitarAssociacaoDeConvenioNaformaDePagamento ?? false })
        this.setState({ habilitarPrintParkImage: data.habilitarPrintParkImage ?? false })
        this.setState({ tamanhoDoPrisma: data.tamanhoDoPrisma ?? "normal" })
        this.setState({ descricaoPrisma: data.descricaoPrisma ?? "Prisma:" })
        this.setState({ habilitarMostrarDetalhesDoTicketNoPagamento: data.habilitarMostrarDetalhesDoTicketNoPagamento ?? false })
        this.setState({ habilitarParcelamentoEmPagamentoDeTicket: data.habilitarParcelamentoEmPagamentoDeTicket ?? false })
        this.setState({ habilitarParcelamentoEmPagamentoDeTicketAPartirDoValor: data.habilitarParcelamentoEmPagamentoDeTicketAPartirDoValor ?? 0 })
        this.setState({ habilitarParcelamentoEmPagamentoDeTicketComJurosPor: data.habilitarParcelamentoEmPagamentoDeTicketComJurosPor ?? "BOTH" })
        this.setState({ habilitarParcelamentoEmPagamentoDeTicketNoMaximoDeParcelas: data.habilitarParcelamentoEmPagamentoDeTicketNoMaximoDeParcelas ?? 0 })
        this.setState({ habilitarParcelamentoEmPagamentoDeMensalista: data.habilitarParcelamentoEmPagamentoDeMensalista ?? false })
        this.setState({ habilitarParcelamentoEmPagamentoDeMensalistaAPartirDoValor: data.habilitarParcelamentoEmPagamentoDeMensalistaAPartirDoValor ?? 0 })
        this.setState({ habilitarParcelamentoEmPagamentoDeMensalistaComJurosPor: data.habilitarParcelamentoEmPagamentoDeMensalistaComJurosPor ?? "BOTH" })
        this.setState({ habilitarParcelamentoEmPagamentoDeMensalistaNoMaximoDeParcelas: data.habilitarParcelamentoEmPagamentoDeMensalistaNoMaximoDeParcelas ?? 0 })
        this.setState({ habilitarEmissaoNotaParaTodosOsPagamentos: data.habilitarEmissaoNotaParaTodosOsPagamentos ?? false })
        this.setState({ habilitarLogsAplicativo: data.habilitarLogsAplicativo ?? false })
        this.setState({ habilitarBuscaDeMarcaEModeloOnline: data.habilitarBuscaDeMarcaEModeloOnline ?? false })
        this.setState({ permitirSaidaTemporariaMensalista: data.permitirSaidaTemporariaMensalista ?? false })
        this.setState({ habilitarEmissaoNotaPorTipo: data.habilitarEmissaoNotaPorTipo ?? "TOTAL" })
        this.setState({ habilitarTaggy: data.habilitarTaggy ?? false })
        this.setState({ codigoEstabelecimentoTaggy: data.codigoEstabelecimentoTaggy ?? "" })
        this.setState({ habilitarGarcomWeb: data.habilitarGarcomWeb ?? false })
        this.setState({ habilitarPDVZanthus: data.habilitarPDVZanthus ?? false })
        this.setState({ X_GW_EC: data.X_GW_EC ?? "" })
        this.setState({ habilitarPatioAoVivo: data.habilitarPatioAoVivo ?? false })
        this.setState({ habilitarPatioAoVivoEmHoras: data.habilitarPatioAoVivoEmHoras ?? "00:00" })
        this.setState({ habilitarPatioAoVivoAoPagarEEntregarUmVeiculo: data.habilitarPatioAoVivoAoPagarEEntregarUmVeiculo ?? false })
        this.setState({ excludedStatus: data.excludedStatus?.map(e => { return { value: e, label: e } }) } ?? [])
        this.setState({ habilitarControlarVagas: data.habilitarControlarVagas ?? false })
        this.setState({ habilitarControlarVagasQuantidade: data.habilitarControlarVagasQuantidade ?? 0 })
        this.setState({ habilitarControlarVagasBloquearEntrada: data.habilitarControlarVagasBloquearEntrada ?? false })
        this.setState({ habilitarEmergenciaNasCancelas: data.habilitarEmergenciaNasCancelas ?? false })
        this.setState({ habilitarReusoDeTicketImpressoNasCancelas: data.habilitarReusoDeTicketImpressoNasCancelas ?? false })
        this.setState({ habilitarReusoDeTicketImpressoNasCancelasAux: data.habilitarReusoDeTicketImpressoNasCancelas ?? false })
        this.setState({ X_API_KEY_TYPE: data.X_API_KEY_TYPE ?? "guid" })
        this.setState({ pagamentoOnlineGatewayName: data.pagamentoOnlineGatewayName ?? "Safe2Pay" })
        this.setState({ pagamentoOnlineGatewayToken: data.pagamentoOnlineGatewayToken ?? "" })
        this.setState({ habilitarPagamentoMensalistasOnline: data.habilitarPagamentoMensalistasOnline ?? false })
        this.setState({ habilitarPagamentoTicketOnline: data.habilitarPagamentoTicketOnline ?? false })
        this.setState({ habilitarCartaoParaAcesso: data.habilitarCartaoParaAcesso ?? false })
        this.setState({ habilitarTicketAvulsoParaMensalista: data.habilitarTicketAvulsoParaMensalista ?? false })
        this.setState({ habilitarF360ERP: data.habilitarF360ERP ?? false })
        this.setState({ identificadorUnicoServicoF360: data.identificadorUnicoServicoF360 })
        this.setState({ habilitarConectCar: data.habilitarConectCar ?? false })
        this.setState({ codigoEstabelecimentoConectCar: data.codigoEstabelecimentoConectCar ?? false })
        this.setState({ codigoEstabelecimentoVeloe: data.codigoEstabelecimentoVeloe ?? "" })
        this.setState({ habilitarVeloe: data.habilitarVeloe ?? false })
        this.setState({ habilitarTotem: data.habilitarTotem ?? false })
        this.setState({ habilitarTotemCompraDeProdutos: data.habilitarTotemCompraDeProdutos ?? false })
        this.setState({ habilitarTotemPagamentoDeTicket: data.habilitarTotemPagamentoDeTicket ?? false })
        this.setState({ habilitarTotemPagamentoDeMensalista: data.habilitarTotemPagamentoDeMensalista ?? false })
        this.loadToken(data)
        this.loadTimeZones()
        this.fillParkImage(data)
        await this.loadPrismSettings()
        this.setState({ isLoading: false })
    }

    loadTimeZones = () => {
        var data = require('../../files/json/timezones.json');
        this.setState({ timezones: data })
    }

    loadToken = (data) => {
        if (!data.X_API_KEY) {
            this.generateNewToken(generateUUIDV7())
        } else {
            this.setState({ X_API_KEY: data.X_API_KEY })
        }
    }

    changeAPIKeyType = (e) => {
        const value = e.target.value
        this.setState({ X_API_KEY_TYPE: value })
        if (value === "guid") {
            this.setState({ X_API_KEY_TEMP: generateUUIDV7() })
        } else if (value === "number") {
            this.setState({ X_API_KEY_TEMP: generateXAPIKey() })
        }
    }

    generateNewToken = async (key) => {
        const data = {
            X_API_KEY: key,
            X_API_KEY_TYPE: this.state.X_API_KEY_TYPE
        }
        await updateDoc({ collection: Collection.PARAMETER, id: this.state.id, data: data })
        this.setState({ X_API_KEY: key })
        await saveLastUpdate()
    }

    saveNewXAPIKEY = async () => {
        var confirm = window.confirm('Deseja mesmo gerar uma nova chave de API?\n\nQualquer sistema que esteja integrado não irão mais funcionar, só aconselhamos gerar um novo token caso este esteja comprometido ou em risco.');
        if (confirm) {
            const type = this.state.X_API_KEY_TYPE
            const key = this.state.X_API_KEY_TEMP
            if (type === "open") {
                if (key.length < 10) {
                    toastWarning("Para a sua segurança é preciso ter 10 digitos uma chave de API.")
                    return
                }
                const parameters = await getParameterByXAPIKey({ X_API_KEY: key })
                if (parameters && parameters?.estacionamentoId !== this.state.estacionamentoId) {
                    toastWarning("Formato de API não válida, tente outro número.")
                    return
                }
            }
            this.generateNewToken(key)
            sendClickButton("Parametros", "Nova X_API_KEY gerada com sucesso!")
            this.hideAPISettingsModal()
        }
    }

    loadPrismSettings = async () => {
        const prismSettings = await getPrismSettings()
        this.setState({ typePrismaEstacionamento: prismSettings?.typePrismaEstacionamento ?? "open_field" })
        this.setState({ maxSequencePrismaEstacionamento: parseInt(prismSettings?.maxSequencePrismaEstacionamento) ?? "0" })
        this.setState({ sequencePrismaEstacionamento: parseInt(prismSettings?.sequencePrismaEstacionamento) ?? "0" })
    }

    savePrismSettings = async (estacionamentoId) => {
        await saveNewPrismSettings({
            estacionamentoId: estacionamentoId,
            typePrismaEstacionamento: this.state.typePrismaEstacionamento,
            maxSequencePrismaEstacionamento: this.state.maxSequencePrismaEstacionamento,
            sequencePrismaEstacionamento: this.state.sequencePrismaEstacionamento
        })
    }

    fillParkImage = async (parameter) => {
        if (parameter.logoParkPrinter) {
            const url = await getURLFromURI(parameter.logoParkPrinter)
            this.setState({ logoParkPrinterExample: url })
        }
    }

    saveParkPrintImage = async (estacionamentoId) => {
        const logoParkPrinter = this.state.logoParkPrinterFile
        if (logoParkPrinter) {
            const result = await uploadParkPrinterImage(estacionamentoId, logoParkPrinter)
            this.setState({ logoParkPrinter: result })
            return result
        }
        return null
    }

    resetPrism = async (estacionamentoId) => {
        await resetPrismSettings({
            estacionamentoId: estacionamentoId,
            typePrismaEstacionamento: this.state.typePrismaEstacionamento,
            maxSequencePrismaEstacionamento: this.state.maxSequencePrismaEstacionamento,
            sequencePrismaEstacionamento: this.state.sequencePrismaEstacionamento
        })
        await saveLastUpdate()
        toastWarning("Sequência salva com sucesso!")
    }

    retriveData = (estacionamentoId) => {
        if (this.state.estacionamentoId === undefined) {
            toastWarning("Selecione o estacionamento.")
            return null
        }
        if (this.state.habilitarMensagemInadimplente === true && this.state.mensagemMensalistaInadimplentes === "") {
            toastWarning("Informe a mensagem que será impressa no ticket para mensalistas inadimplentes.")
            return null
        }
        if (this.state.habilitarMensagemImpressaNoTicket === true && this.state.mensagemLancamentoTicket === "") {
            toastWarning("Informe a mensagem que será impressa no ticket.")
            return null
        }
        if (this.state.habilitarAcrecimoAposVencimento === true && this.state.acrecimoAposVencimentoPorcentagem === "") {
            toastWarning("Informe o acrécimo após o vencimento.")
            return null
        }
        if (this.state.habilitarDescontoAntesDoVencimento === true && this.state.descontoAntesDoVencimentoPorcentagem === "") {
            toastWarning("Informe o desconto para pagamento antes do vencimento.")
            return null
        }
        if (this.state.typePrismaEstacionamento !== "open_field" && this.state.maxSequencePrismaEstacionamento === 0) {
            toastWarning("Informe até que número vai a sequência de prisma do estabelecimento.")
            return null
        }
        if (this.state.habilitarSemParar === true && (this.state.codigoEstabelecimentoSemParar === "" || this.state.hashEstabelecimentoSemParar === "")) {
            toastWarning("O código do estabelecimento é obrigatório para habilitar o Sem Parar")
            return null
        }
        return {
            clienteId: getCliente().id,
            estacionamentoId: estacionamentoId,
            trocoInicialCaixa: this.state.trocoInicialCaixa,
            prismaEstacionamento: this.state.prismaEstacionamento,
            setorEstacionamento: this.state.setorEstacionamento,
            fecharCaixaParcial: this.state.fecharCaixaParcial,
            fecharCaixaCego: this.state.fecharCaixaCego,
            habilitarCobrancaMensalista: this.state.habilitarCobrancaMensalista,
            habilitarCartaoParaAcesso: this.state.habilitarCartaoParaAcesso,
            habilitarTicketAvulsoParaMensalista: this.state.habilitarTicketAvulsoParaMensalista,
            habilitarTarifaAposVencimento: this.state.habilitarTarifaAposVencimento,
            tarifaAposVencimenoDias: this.state.tarifaAposVencimenoDias,
            habilitarMensagemInadimplente: this.state.habilitarMensagemInadimplente,
            mensagemMensalistaInadimplentes: this.state.mensagemMensalistaInadimplentes,
            habilitarDescontoAntesDoVencimento: this.state.habilitarDescontoAntesDoVencimento,
            descontoAntesDoVencimentoPorcentagem: this.state.descontoAntesDoVencimentoPorcentagem,
            descontoAntesDoVencimentoDias: this.state.descontoAntesDoVencimentoDias,
            habilitarAcrecimoAposVencimento: this.state.habilitarAcrecimoAposVencimento,
            acrecimoAposVencimentoPorcentagem: this.state.acrecimoAposVencimentoPorcentagem,
            acrecimoAposVencimentoDias: this.state.acrecimoAposVencimentoDias,
            habilitarEdicaoAvaliacaoInternaNoTicket: this.state.habilitarEdicaoAvaliacaoInternaNoTicket,
            habilitarEdicaoPlacaNoTicket: this.state.habilitarEdicaoPlacaNoTicket,
            habilitarEdicaoAvaliacaoExternaNoTicket: this.state.habilitarEdicaoAvaliacaoExternaNoTicket,
            habilitarObrigatoriedadeAvaliacaoExterna: this.state.habilitarObrigatoriedadeAvaliacaoExterna,
            habilitarTipoDeAvariaAvaliacaoExterna: this.state.habilitarTipoDeAvariaAvaliacaoExterna,
            habilitarFecharCaixaPorPeriodo: this.state.habilitarFecharCaixaPorPeriodo,
            habilitarImprimirTabelaPrecoNoTicket: this.state.habilitarImprimirTabelaPrecoNoTicket,
            habilitarObrigatoriedadeModeloCorDoVeiculo: this.state.habilitarObrigatoriedadeModeloCorDoVeiculo,
            habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo: this.state.habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo,
            habilitarObrigatoriedadeModeloCorDoVeiculoPorFoto: this.state.habilitarObrigatoriedadeModeloCorDoVeiculoPorFoto,
            habilitarObrigatoriedadeTabelaDePrecoPorVeiculo: this.state.habilitarObrigatoriedadeTabelaDePrecoPorVeiculo,
            habilitarMensagemImpressaNoTicket: this.state.habilitarMensagemImpressaNoTicket,
            mensagemLancamentoTicketLocal: this.state.mensagemLancamentoTicketLocal,
            mensagemLancamentoTicket: this.state.mensagemLancamentoTicket,
            habilitarMensagemQuandoSelecionadaNoTicket: this.state.habilitarMensagemQuandoSelecionadaNoTicket,
            mensagemQuandoSelecionadaNoTicket: this.state.mensagemQuandoSelecionadaNoTicket,
            mensagemQuandoSelecionadaNoTicketLocal: this.state.mensagemQuandoSelecionadaNoTicketLocal,
            tituloQuandoSelecionadaNoTicket: this.state.tituloQuandoSelecionadaNoTicket,
            tituloQuandoSelecionadaNoTicketTamanho: this.state.tituloQuandoSelecionadaNoTicketTamanho,
            habilitarRetiradaDeCaixaAcima: this.state.habilitarRetiradaDeCaixaAcima,
            habilitarDescontoManual: this.state.habilitarDescontoManual ?? false,
            habilitarTicketResumido: this.state.habilitarTicketResumido ?? false,
            periodoFecharCaixaApartirDeUmHorario: this.state.periodoFecharCaixaApartirDeUmHorario?.toDate() ?? new Date(),
            periodoFecharCaixaApartirDeUmHorarioEmHoras: this.state.periodoFecharCaixaApartirDeUmHorarioEmHoras,
            privadoHabilitarPermitirEnviarSMS: this.state.privadoHabilitarPermitirEnviarSMS ?? false,
            privadoHabilitarPermitirEnviarWhatsApp: this.state.privadoHabilitarPermitirEnviarWhatsApp ?? false,
            habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo: this.state.habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo ?? false,
            imprimirTicketNoFormato: this.state.imprimirTicketNoFormato,
            tamanhoDoNomeDoEstacionamento: this.state.tamanhoDoNomeDoEstacionamento ?? "normal",
            habilitarSemParar: this.state.habilitarSemParar,
            codigoEstabelecimentoSemParar: this.state.codigoEstabelecimentoSemParar,
            hashEstabelecimentoSemParar: this.state.hashEstabelecimentoSemParar,
            enviarTicketSemParar: this.state.enviarTicketSemParar,
            datahabilitarNumeroImpressoNoTicket: this.state.datahabilitarNumeroImpressoNoTicket,
            habilitarDataSaida: this.state.habilitarDataSaida,
            habilitarTicketBatch: this.state.habilitarTicketBatch,
            habilitarObrigatoriedadeModeloCorDoVeiculoRapida: this.state.habilitarObrigatoriedadeModeloCorDoVeiculoRapida,
            typeKeyboard: this.state.typeKeyboard,
            habilitarDescontoManualMensalista: this.state.habilitarDescontoManualMensalista,
            printCustomImage: this.state.printCustomImage,
            printWebSite: this.state.printWebSite ?? true,
            privadoHabilitarNovaCameraAndroid: this.state.privadoHabilitarNovaCameraAndroid,
            algoritmoDeImpressao: this.state.algoritmoDeImpressao,
            habilitarMensalistaImpressaoTicketEntrada: this.state.habilitarMensalistaImpressaoTicketEntrada,
            habilitarAlterarOperadorAoAbrirCaixa: this.state.habilitarAlterarOperadorAoAbrirCaixa,
            habilitarRotacionarVagaMensalista: this.state.habilitarRotacionarVagaMensalista,
            habilitarDataEntrada: this.state.habilitarDataEntrada,
            habilitarPagamentosDeMultiplasMensalidadesDoMensalista: this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalista,
            habilitarPagamentosDeMultiplasMensalidadesDoMensalistaMeses: this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalistaMeses,
            habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista: this.state.habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista,
            timezone: this.state.timezone,
            habilitarImprimirObservacaoRetirada: this.state.habilitarImprimirObservacaoRetirada,
            observacaoEstacionamento: this.state.observacaoEstacionamento,
            habilitarCampoContato: this.state.habilitarCampoContato,
            habilitarCampoContatoNome: this.state.habilitarCampoContatoNome,
            habilitarCampoContatoNomeObrigatorio: this.state.habilitarCampoContatoNomeObrigatorio,
            habilitarCampoContatoNomeEmpresa: this.state.habilitarCampoContatoNomeEmpresa,
            habilitarCampoContatoNomeEmpresaObrigatorio: this.state.habilitarCampoContatoNomeEmpresaObrigatorio,
            habilitarCampoContatoCelular: this.state.habilitarCampoContatoCelular,
            habilitarCampoContatoCelularObrigatorio: this.state.habilitarCampoContatoCelularObrigatorio,
            habilitarCampoContatoEmail: this.state.habilitarCampoContatoEmail,
            habilitarCampoContatoEmailObrigatorio: this.state.habilitarCampoContatoEmailObrigatorio,
            habilitarCampoContatoTelefone: this.state.habilitarCampoContatoTelefone,
            habilitarCampoContatoTelefoneObrigatorio: this.state.habilitarCampoContatoTelefoneObrigatorio,
            habilitarCampoContatoDocumento: this.state.habilitarCampoContatoDocumento,
            habilitarCampoContatoDocumentoObrigatorio: this.state.habilitarCampoContatoDocumentoObrigatorio,
            habilitarCampoContatoRG: this.state.habilitarCampoContatoRG,
            habilitarCampoContatoRGObrigatorio: this.state.habilitarCampoContatoRGObrigatorio,
            habilitarCampoContatoDepartamento: this.state.habilitarCampoContatoDepartamento,
            habilitarCampoContatoDepartamentoObrigatorio: this.state.habilitarCampoContatoDepartamentoObrigatorio,
            habilitarCampoContatoObservacao: this.state.habilitarCampoContatoObservacao,
            habilitarCampoContatoObservacaoObrigatorio: this.state.habilitarCampoContatoObservacaoObrigatorio,
            habilitarCampoContatoMarcaModelo: this.state.habilitarCampoContatoMarcaModelo,
            habilitarCampoContatoMarcaModeloObrigatorio: this.state.habilitarCampoContatoMarcaModeloObrigatorio,
            habilitarCampoContatoFotoMotorista: this.state.habilitarCampoContatoFotoMotorista,
            habilitarCampoContatoFotoMotoristaObrigatorio: this.state.habilitarCampoContatoFotoMotoristaObrigatorio,
            habilitarCampoContatoFotoHabilitacao: this.state.habilitarCampoContatoFotoHabilitacao,
            habilitarCampoContatoFotoHabilitacaoObrigatorio: this.state.habilitarCampoContatoFotoHabilitacaoObrigatorio,
            habilitarImprimirTituloDocumentoNaoFiscal: this.state.habilitarImprimirTituloDocumentoNaoFiscal,
            habilitarProdutosConsumidosNaProximaFatura: this.state.habilitarProdutosConsumidosNaProximaFatura,
            habilitarCredenciadosCadastrarViaLink: this.state.habilitarCredenciadosCadastrarViaLink,
            habilitarCompartilhamentoDeMensalistasNaRede: this.state.habilitarCompartilhamentoDeMensalistasNaRede,
            habilitarCancela: this.state.habilitarCancela,
            cancelaURLStatus: this.state.cancelaURLStatus,
            cancelaWebHook: this.state.cancelaWebHook,
            cancelaNotificarStatus: this.state.cancelaNotificarStatus.map(e => e.value),
            habilitarImpressaoDadosEstabelecimentoNome: this.state.habilitarImpressaoDadosEstabelecimentoNome,
            habilitarImpressaoDadosEstabelecimentoDocumento: this.state.habilitarImpressaoDadosEstabelecimentoDocumento,
            habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento: this.state.habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento,
            habilitarImpressaoDadosEstabelecimentoEndereco: this.state.habilitarImpressaoDadosEstabelecimentoEndereco,
            habilitarImpressaoDadosEstabelecimentoCelular: this.state.habilitarImpressaoDadosEstabelecimentoCelular,
            habilitarZerarPrisma: this.state.habilitarZerarPrisma,
            habilitarAutoAtendimento: this.state.habilitarAutoAtendimento,
            habilitarOnlineCalculation: this.state.habilitarOnlineCalculation,
            habilitarPagamentoTicketOnline: this.state.habilitarPagamentoTicketOnline,
            habilitarSeniorERP: this.state.habilitarSeniorERP,
            idCompanySenior: this.state.idCompanySenior,
            idBranchSenior: this.state.idBranchSenior,
            idCustomerSenior: this.state.idCustomerSenior,
            idItemServiceSenior: this.state.idItemServiceSenior,
            accessKeySenior: this.state.accessKeySenior,
            secretSenior: this.state.secretSenior,
            tenantNameSenior: this.state.tenantNameSenior,
            habilitarTelaPrismaLancamentoRapido: this.state.habilitarTelaPrismaLancamentoRapido,
            habilitarZerarPrismaValorPadrao: this.state.habilitarZerarPrismaValorPadrao,
            habilitarValorTotalNoTicketDeCaixasAnteriores: this.state.habilitarValorTotalNoTicketDeCaixasAnteriores,
            habilitarImpressaoDeRelatorioNoFechamentoDoCaixa: this.state.habilitarImpressaoDeRelatorioNoFechamentoDoCaixa,
            habilitarMovimentacoesDeCaixa: this.state.habilitarMovimentacoesDeCaixa,
            typeCashierOpened: this.state.typeCashierOpened,
            habilitarMostrarCoresDosVeiculosNaListagem: this.state.habilitarMostrarCoresDosVeiculosNaListagem,
            habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem: this.state.habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem,
            excludedStatus: this.state.excludedStatus?.map(e => e.value) ?? [],
            habilitarCalculadoraAoSelecionarDinheiro: this.state.habilitarCalculadoraAoSelecionarDinheiro,
            habilitarAssociacaoDeConvenioNaformaDePagamento: this.state.habilitarAssociacaoDeConvenioNaformaDePagamento,
            habilitarPrintParkImage: this.state.habilitarPrintParkImage,
            tamanhoDoPrisma: this.state.tamanhoDoPrisma,
            descricaoPrisma: this.state.descricaoPrisma,
            habilitarMostrarDetalhesDoTicketNoPagamento: this.state.habilitarMostrarDetalhesDoTicketNoPagamento,
            habilitarParcelamentoEmPagamentoDeTicket: this.state.habilitarParcelamentoEmPagamentoDeTicket,
            habilitarParcelamentoEmPagamentoDeTicketAPartirDoValor: this.state.habilitarParcelamentoEmPagamentoDeTicketAPartirDoValor,
            habilitarParcelamentoEmPagamentoDeTicketComJurosPor: this.state.habilitarParcelamentoEmPagamentoDeTicketComJurosPor,
            habilitarParcelamentoEmPagamentoDeTicketNoMaximoDeParcelas: parseInt(this.state.habilitarParcelamentoEmPagamentoDeTicketNoMaximoDeParcelas),
            habilitarParcelamentoEmPagamentoDeMensalista: this.state.habilitarParcelamentoEmPagamentoDeMensalista,
            habilitarParcelamentoEmPagamentoDeMensalistaAPartirDoValor: this.state.habilitarParcelamentoEmPagamentoDeMensalistaAPartirDoValor,
            habilitarParcelamentoEmPagamentoDeMensalistaComJurosPor: this.state.habilitarParcelamentoEmPagamentoDeMensalistaComJurosPor,
            habilitarParcelamentoEmPagamentoDeMensalistaNoMaximoDeParcelas: parseInt(this.state.habilitarParcelamentoEmPagamentoDeMensalistaNoMaximoDeParcelas),
            habilitarMostrarDetalhesDoTicketNoPagamento: this.state.habilitarMostrarDetalhesDoTicketNoPagamento,
            logoParkPrinter: this.state.logoParkPrinter,
            habilitarEmissaoNotaParaTodosOsPagamentos: this.state.habilitarEmissaoNotaParaTodosOsPagamentos,
            habilitarEmissaoNotaPorTipo: this.state.habilitarEmissaoNotaPorTipo,
            habilitarTaggy: this.state.habilitarTaggy,
            codigoEstabelecimentoTaggy: this.state.codigoEstabelecimentoTaggy,
            habilitarGarcomWeb: this.state.habilitarGarcomWeb,
            habilitarPDVZanthus: this.state.habilitarPDVZanthus,
            X_GW_EC: this.state.X_GW_EC,
            habilitarLogsAplicativo: this.state.habilitarLogsAplicativo,
            habilitarBuscaDeMarcaEModeloOnline: this.state.habilitarBuscaDeMarcaEModeloOnline,
            permitirSaidaTemporariaMensalista: this.state.permitirSaidaTemporariaMensalista,
            habilitarPatioAoVivo: this.state.habilitarPatioAoVivo,
            habilitarPatioAoVivoEmHoras: this.state.habilitarPatioAoVivoEmHoras,
            habilitarPatioAoVivoAoPagarEEntregarUmVeiculo: this.state.habilitarPatioAoVivoAoPagarEEntregarUmVeiculo,
            habilitarControlarVagas: this.state.habilitarControlarVagas,
            habilitarControlarVagasQuantidade: parseInt(this.state.habilitarControlarVagasQuantidade),
            habilitarControlarVagasBloquearEntrada: this.state.habilitarControlarVagasBloquearEntrada,
            habilitarEmergenciaNasCancelas: this.state.habilitarEmergenciaNasCancelas,
            habilitarReusoDeTicketImpressoNasCancelas: this.state.habilitarReusoDeTicketImpressoNasCancelas,
            pagamentoOnlineGatewayName: this.state.pagamentoOnlineGatewayName,
            pagamentoOnlineGatewayToken: this.state.pagamentoOnlineGatewayToken,
            pagamentoOnlineShipayAccessKey: this.state.pagamentoOnlineShipayAccessKey,
            pagamentoOnlineShipaySecretKey: this.state.pagamentoOnlineShipaySecretKey,
            pagamentoOnlineShipayClientId: this.state.pagamentoOnlineShipayClientId,
            habilitarPagamentoMensalistasOnline: this.state.habilitarPagamentoMensalistasOnline,
            habilitarPagamentoTicketOnline: this.state.habilitarPagamentoTicketOnline,
            habilitarObrigacaoParaAtualizarOsParametros: this.state.habilitarObrigacaoParaAtualizarOsParametros,
            habilitarOCRNaFotoDoVeiculo: this.state.habilitarOCRNaFotoDoVeiculo,
            bloquearEntradaAvulso: this.state.bloquearEntradaAvulso,
            quantidadeDeFotosAvaliacaoExterna: parseInt(this.state.quantidadeDeFotosAvaliacaoExterna ?? 5),
            habilitarF360ERP: this.state.habilitarF360ERP,
            identificadorUnicoServicoF360: this.state.identificadorUnicoServicoF360,
            documentF360: this.state.documentF360,
            habilitarConectCar: this.state.habilitarConectCar,
            codigoEstabelecimentoConectCar: this.state.codigoEstabelecimentoConectCar,
            codigoEstabelecimentoVeloe: this.state.codigoEstabelecimentoVeloe,
            habilitarVeloe: this.state.habilitarVeloe,
            habilitarTotem: this.state.habilitarTotem,
            habilitarTotemCompraDeProdutos: this.state.habilitarTotemCompraDeProdutos,
            habilitarTotemPagamentoDeTicket: this.state.habilitarTotemPagamentoDeTicket,
            habilitarTotemPagamentoDeMensalista: this.state.habilitarTotemPagamentoDeMensalista,
        }
    }

    update = async () => {
        const data = this.retriveData(this.state.estacionamentoId)
        data.id = this.state.id
        data.X_API_KEY = this.state.X_API_KEY
        const result = await this.updateParametersByParkId(data)
        if (result) {
            reloadWithAlert("Parametros atualizados com sucesso!")
        }
    }

    updateAll = async () => {
        const confirm = window.confirm("Atenção: esta ação irá atualizar todos os parâmetros do seu sistema, por favor, confirme que deseja continuar.")
        if (confirm) {
            const parameters = await getAllParametersByClienteId({ clienteId: this.state.clienteId })
            for (const parameter of parameters) {
                const data = this.retriveData(parameter.estacionamentoId)
                data.id = parameter.id
                data.X_API_KEY = parameter.X_API_KEY
                console.log(data);
                await this.updateParametersByParkId(data)
            }
            reloadWithAlert("Parametros atualizados com sucesso!")
        }
    }

    updateParametersByParkId = async (data) => {
        if (data) {
            const logoParkPrinter = await this.saveParkPrintImage(data.estacionamentoId)
            if (logoParkPrinter) {
                data.logoParkPrinter = logoParkPrinter
            }
            await firebase.firestore().collection('parametros').doc(data.id).update(data)
            await this.savePrismSettings(data.estacionamentoId)
            await this.saveCommandToEquipmentIfNeed(data.estacionamentoId)
            await saveLastUpdate(data.estacionamentoId)
            sendClickButton("Parametros", "Atualizar", true, data)
            return true
        }
        return false
    }

    saveCommandToEquipmentIfNeed = async (estacionamentoId) => {
        await equipmentManagerReloadAllEquipments(estacionamentoId)
    }

    sendWhatsAppMessage = (isChecked) => {
        this.setState({ privadoHabilitarPermitirEnviarWhatsApp: isChecked })
        this.setState({ privadoHabilitarPermitirEnviarSMS: false })
    }

    sendSMSMessage = (isChecked) => {
        this.setState({ privadoHabilitarPermitirEnviarWhatsApp: false })
        this.setState({ privadoHabilitarPermitirEnviarSMS: isChecked })
    }

    handleAlterNotifyStatus = (selected) => {
        this.setState({ cancelaNotificarStatus: selected })
    };

    changeTab = (tab) => {
        setPathWindow(`/configuracao/parametros?tab=${tab}`)
        this.setState({ key: tab })
    }

    handleExcludedStatus = (selected) => {
        this.setState({ excludedStatus: selected })
    };

    showSeniorSettingsModal = async () => {
        const wayPayments = await getFormasPagamentosAtivas({ estacionamentoId: this.state.estacionamentoId })
        const seniorCodeNotAdded = wayPayments.filter(e => !e.seniorCode || e.seniorCode === "").length > 0
        this.setState({ seniorCodeNotAdded: seniorCodeNotAdded })
        this.setState({ isShowSeniorSettingsModal: true })
    }

    showCashierCategoriesModal = async () => {
        this.setState({ isShowCashierCategoriesModal: true })
    }

    changeCamposContato = (e) => {
        const value = e.target.checked
        if (value) {
            this.showCamposContatoModal()
        }
        this.setState({ habilitarCampoContato: e.target.checked })
    }

    showCamposContatoModal = () => {
        this.setState({ isShowCamposContatosModal: true })
    }

    onCloseMovementCategories = async () => {
        this.setState({ isShowCashierCategoriesModal: false })
    }

    hideSeniorSettingsModal = () => {
        this.setState({ isShowSeniorSettingsModal: false })
    }

    showAPISettingsModal = () => {
        this.setState({ isShowAPISettingsModal: true })
    }

    hideAPISettingsModal = () => {
        this.setState({ isShowAPISettingsModal: false })
    }

    hideResyncSeniorModal = () => {
        this.setState({ isShowResyncSeniorModal: false })
    }

    showResyncSeniorModal = () => {
        this.setState({ isShowResyncSeniorModal: true })
    }

    confirmResyncSenior = async () => {
        this.setState({ isShowResyncSeniorLoading: true })
        const option = {
            method: "POST"
        };
        await fetchFunction('/senior/resync', option)
    }

    saveSeniorSettings = async () => {
        if (!this.checkSeniorFields()) {
            return
        }
        await firebase.firestore().collection('parametros').doc(this.state.id).update({
            habilitarSeniorERP: this.state.habilitarSeniorERP,
            idCompanySenior: this.state.idCompanySenior,
            idBranchSenior: this.state.idBranchSenior,
            idCustomerSenior: this.state.idCustomerSenior,
            idItemServiceSenior: this.state.idItemServiceSenior,
            accessKeySenior: this.state.accessKeySenior,
            secretSenior: this.state.secretSenior,
            tenantNameSenior: this.state.tenantNameSenior,
        })
        await saveLastUpdate()
        toastSuccess("Parametros atualizados com sucesso!")
        sendClickButton("Parametros", "Atualizar parametros sênior")
        this.hideSeniorSettingsModal()
    }

    checkSeniorFields = () => {
        if (!this.state.accessKeySenior || this.state.accessKeySenior === "") {
            toastWarning("Informe a chave da sênior.")
            return false
        }
        if (!this.state.secretSenior || this.state.secretSenior === "") {
            toastWarning("Informe a chave secreta da sênior.")
            return false
        }
        if (!this.state.tenantNameSenior || this.state.tenantNameSenior === "") {
            toastWarning("Informe o o tenant da sênior.")
            return false
        }
        if (!this.state.idCompanySenior || this.state.idCompanySenior === "") {
            toastWarning("Informe o identificador da compania.")
            return false
        }
        if (!this.state.idBranchSenior || this.state.idBranchSenior === "") {
            toastWarning("Informe o identificador da conta.")
            return false
        }
        if (!this.state.idCustomerSenior || this.state.idCustomerSenior === "") {
            toastWarning("Informe o identificador do cliente.")
            return false
        }
        return true
    }

    handlePrinterFileChange = e => {
        const file = e.target.files[0]
        this.setState({ logoParkPrinterFile: file })
        const url = URL.createObjectURL(file)
        this.setState({ logoParkPrinterExample: url })
    }

    atualizarParcelamentoEmPagamentoDeTicketAPartirDoValor = (event, maskedvalue, floatvalue) => {
        this.setState({ habilitarParcelamentoEmPagamentoDeTicketAPartirDoValor: floatvalue })
    }

    atualizarParcelamentoEmPagamentoDeMensalistaAPartirDoValor = (event, maskedvalue, floatvalue) => {
        this.setState({ habilitarParcelamentoEmPagamentoDeMensalistaAPartirDoValor: floatvalue })
    }

    changeTypeCashierOpened = async (value) => {
        const currentCashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
        if (currentCashier) {
            toastWarning("Para alterar o modo de caixa, não pode ter nenhum caixa aberto.")
            goToNewWindow(`/caixas/${currentCashier.id}`)
        } else {
            this.setState({ typeCashierOpened: value })
        }
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Parâmetros
                        </div>
                        {
                            this.state.isLoading && <Loading />
                        }
                        {
                            !this.state.isLoading && <>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={tab => this.changeTab(tab)}>
                                                <Tab eventKey="caixa" title="Caixa">
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="trocoInicialCaixa" checked={this.state.trocoInicialCaixa} onChange={(e) => this.setState({ trocoInicialCaixa: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="trocoInicialCaixa">
                                                            Permitir troco inicial ao abrir o caixa.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="fecharCaixaParcial" checked={this.state.fecharCaixaParcial} onChange={(e) => this.setState({ fecharCaixaParcial: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="fecharCaixaParcial">
                                                            Permitir fechar o caixa parcial.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarRetiradaDeCaixaAcima" checked={this.state.habilitarRetiradaDeCaixaAcima} onChange={(e) => this.setState({ habilitarRetiradaDeCaixaAcima: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarRetiradaDeCaixaAcima">
                                                            Permitir fazer retirada de caixa com valores maiores que os já lançados no caixa aberto.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarImprimirObservacaoRetirada" checked={this.state.habilitarImprimirObservacaoRetirada} onChange={(e) => this.setState({ habilitarImprimirObservacaoRetirada: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarImprimirObservacaoRetirada">
                                                            Permitir imprimir a descrição da retirada no registro.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarFecharCaixaPorPeriodo" checked={this.state.habilitarFecharCaixaPorPeriodo} onChange={(e) => this.setState({ habilitarFecharCaixaPorPeriodo: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarFecharCaixaPorPeriodo">
                                                            Obrigar que o operador feche o caixa a partir de um certo horário.
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.habilitarFecharCaixaPorPeriodo === true &&
                                                        <div className="col-lg-2 my-2">
                                                            <NumberFormat className="form-control" value={this.state.periodoFecharCaixaApartirDeUmHorarioEmHoras} mask='_' onChange={(e) => this.setState({ periodoFecharCaixaApartirDeUmHorarioEmHoras: e.target.value })} format="##:##" />
                                                        </div>
                                                    }
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarAlterarOperadorAoAbrirCaixa" checked={this.state.habilitarAlterarOperadorAoAbrirCaixa} onChange={(e) => this.setState({ habilitarAlterarOperadorAoAbrirCaixa: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarAlterarOperadorAoAbrirCaixa">
                                                            Alterar o operador do aplicativo ( via código de acesso ) ao abrir um novo caixa.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarValorTotalNoTicketDeCaixasAnteriores" checked={this.state.habilitarValorTotalNoTicketDeCaixasAnteriores} onChange={(e) => this.setState({ habilitarValorTotalNoTicketDeCaixasAnteriores: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarValorTotalNoTicketDeCaixasAnteriores">
                                                            Apresentar valor total na listagem dos tickets mesmo com pagamento em caixas anteriores.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarImpressaoDeRelatorioNoFechamentoDoCaixa" checked={this.state.habilitarImpressaoDeRelatorioNoFechamentoDoCaixa} onChange={(e) => this.setState({ habilitarImpressaoDeRelatorioNoFechamentoDoCaixa: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarImpressaoDeRelatorioNoFechamentoDoCaixa">
                                                            Imprimir relatório de caixa após o fechamento.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarMovimentacoesDeCaixa" checked={this.state.habilitarMovimentacoesDeCaixa} onChange={(e) => this.setState({ habilitarMovimentacoesDeCaixa: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarMovimentacoesDeCaixa">
                                                            Habilitar movimentações no caixa ( controle de entrada e saída ).
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.habilitarMovimentacoesDeCaixa === true &&
                                                        <div className='col-lg-3 my-2'>
                                                            <button type="button" onClick={this.showCashierCategoriesModal} className="btn btn-success btn-sm">Cadastrar ou visualizar categorias</button>
                                                        </div>
                                                    }
                                                    <div className="col-lg-3">
                                                        <label className="form-check-label" htmlFor="typeCashierOpened">
                                                            Tipo de caixa aberto
                                                        </label>
                                                        <div className='mt-2'>
                                                            <select className="form-select" value={this.state.typeCashierOpened} onChange={(e) => this.changeTypeCashierOpened(e.target.value)}>
                                                                <option value="park">Estabelecimento</option>
                                                                <option value="user">Usuários</option>
                                                            </select>
                                                        </div>
                                                        {
                                                            this.state.typeCashierOpened === "park" &&
                                                            <div className='mt-3'>
                                                                <AlertSuccess message="Só existirá um caixa por estabelecimento e todos os pagamentos dos outros usuários serão associados a este caixa." />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.typeCashierOpened === "user" &&
                                                            <div className='mt-3'>
                                                                <AlertDanger message="Cada usuário tera que abrir um caixa e todos os pagamentos deste usuário ficarão associados a este caixa." />
                                                            </div>
                                                        }
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="estacionamento" title="Estacionamento">
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarControlarVagas" checked={this.state.habilitarControlarVagas} onChange={(e) => this.setState({ habilitarControlarVagas: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarControlarVagas">
                                                            Controlar quantidade de vagas do estacionamento.
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.habilitarControlarVagas && <>
                                                            <div className="col-lg-4">
                                                                <div className='col-lg-5'>
                                                                    <span className="my-2">Quantidade de vagas</span>
                                                                    <input type="number" onChange={(e) => this.setState({ habilitarControlarVagasQuantidade: e.target.value })} value={this.state.habilitarControlarVagasQuantidade} className="form-control" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="prismaEstacionamento" checked={this.state.prismaEstacionamento} onChange={(e) => this.setState({ prismaEstacionamento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="prismaEstacionamento">
                                                            Habilitar campo de prisma na entrada do veículo.
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.prismaEstacionamento && <>
                                                            <div className="col-lg-4">
                                                                <div className="alert alert-secondary my-3" role="alert">
                                                                    <div className="col-lg-12 mb-3">
                                                                        <select className="form-select" value={this.state.typePrismaEstacionamento && this.state.typePrismaEstacionamento} onChange={(e) => this.setState({ typePrismaEstacionamento: e.target.value })}>
                                                                            <option value="open_field">Campo aberto</option>
                                                                            <option value="stamp">Cartela</option>
                                                                            <option value="sequence">Número sequêncial</option>
                                                                            <option value="sequence_unique">Número sequêncial único</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="alert alert-danger" role="alert">
                                                                        {
                                                                            this.state.typePrismaEstacionamento === "open_field" &&
                                                                            <div>
                                                                                <div>
                                                                                    <strong>Explicação: </strong>
                                                                                    <span>
                                                                                        O operador pode digitar qualquer número no prisma, mas é impedido caso o número do prisma esteja sendo útilizado por outro veículo no pátio. <p />
                                                                                        O número fica livre novamente assim que o veículo for pago e entregue ou cancelado.
                                                                                    </span>
                                                                                </div>
                                                                                <div className='row my-3'>
                                                                                    <div className='col-lg-4'>
                                                                                        <span className="my-2">Sequência atual</span>
                                                                                        <input type="number" onChange={(e) => this.setState({ sequencePrismaEstacionamento: e.target.value })} value={this.state.sequencePrismaEstacionamento} className="form-control" />
                                                                                    </div>
                                                                                    <div className='col-lg-4'>
                                                                                        <button type="button" onClick={this.resetPrism} className="btn btn-danger mt-4 w-100">Salvar sequência</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.typePrismaEstacionamento === "stamp" &&
                                                                            <div>
                                                                                <div>
                                                                                    <strong>Explicação: </strong>
                                                                                    <span>Só renova depois que acabar a sequência da cartela ( você pode editar por aqui esses dados ).</span><br />
                                                                                </div>
                                                                                <div className='row my-3'>
                                                                                    <div className='col-lg-4'>
                                                                                        <span className="mt-3">Vai até o número</span>
                                                                                        <input type="number" onChange={(e) => this.setState({ maxSequencePrismaEstacionamento: e.target.value })} value={this.state.maxSequencePrismaEstacionamento} className="form-control" />
                                                                                    </div>
                                                                                    <div className='col-lg-4'>
                                                                                        <span className="my-2">Sequência atual</span>
                                                                                        <input type="number" onChange={(e) => this.setState({ sequencePrismaEstacionamento: e.target.value })} value={this.state.sequencePrismaEstacionamento} className="form-control" />
                                                                                    </div>
                                                                                    <div className='col-lg-4'>
                                                                                        <button type="button" onClick={this.resetPrism} className="btn btn-danger mt-4 w-100">Salvar sequência</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.typePrismaEstacionamento === "sequence" &&
                                                                            <div>
                                                                                <div>
                                                                                    <strong>Explicação: </strong>
                                                                                    <span>O operador pode digitar qualquer número no prisma ( mas já vem auto-preenchido o próximo livre ).
                                                                                        <br /> O número fica livre novamente assim que o veículo for pago e entregue ou cancelado.</span><br />
                                                                                </div>
                                                                                <div className='row my-3'>
                                                                                    <div className='col-lg-4'>
                                                                                        <span className="my-2">Sequência atual</span>
                                                                                        <input type="number" onChange={(e) => this.setState({ sequencePrismaEstacionamento: e.target.value })} value={this.state.sequencePrismaEstacionamento} className="form-control" />
                                                                                    </div>
                                                                                    <div className='col-lg-4'>
                                                                                        <button type="button" onClick={this.resetPrism} className="btn btn-danger mt-4 w-100">Salvar sequência</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.typePrismaEstacionamento === "sequence_unique" &&
                                                                            <div>
                                                                                <div>
                                                                                    <strong>Explicação: </strong>
                                                                                    <span>
                                                                                        O operador pode digitar qualquer número no prisma ( mas já vem auto-preenchido o próximo livre ).
                                                                                        O número <strong>nunca</strong> fica livre novamente até que seja zerado o prisma no fechamento do caixa.
                                                                                    </span>
                                                                                </div>
                                                                                <div className='row my-3'>
                                                                                    <div className='col-lg-4'>
                                                                                        <span className="my-2">Sequência atual</span>
                                                                                        <input type="number" onChange={(e) => this.setState({ sequencePrismaEstacionamento: e.target.value })} value={this.state.sequencePrismaEstacionamento} className="form-control" />
                                                                                    </div>
                                                                                    <div className='col-lg-4'>
                                                                                        <button type="button" onClick={this.resetPrism} className="btn btn-danger mt-4 w-100">Salvar sequência</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <strong>Atenção</strong> o aplicativo trabalha 100% offline caso tenha mais de uma maquininha não garantimos que não haja duplicações na sequência. <p /> <strong>Não utilize este dado como identificador único do ticket.</strong>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarTelaPrismaLancamentoRapido" checked={this.state.habilitarTelaPrismaLancamentoRapido} onChange={(e) => this.setState({ habilitarTelaPrismaLancamentoRapido: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarTelaPrismaLancamentoRapido">
                                                                            Mostrar tela de prisma no lançamento rápido do veículo.
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarZerarPrisma" checked={this.state.habilitarZerarPrisma} onChange={(e) => this.setState({ habilitarZerarPrisma: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarZerarPrisma">
                                                                            Permitir zerar o prisma ao fechar um caixa.
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarZerarPrisma &&
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarZerarPrismaValorPadrao" checked={this.state.habilitarZerarPrismaValorPadrao} onChange={(e) => this.setState({ habilitarZerarPrismaValorPadrao: e.target.checked })} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarZerarPrismaValorPadrao">
                                                                                Deixar por padrão habilitado a flag de zerar prisma ao fechar o caixa.
                                                                            </label>
                                                                        </div>
                                                                    }
                                                                    <div className="col-lg-12">
                                                                        <label className="form-check-label" htmlFor="habilitarImpressaoDadosEstabelecimentoPrisma">
                                                                            Tamanho do prisma na impressão.
                                                                        </label>
                                                                        <select className="form-select" value={this.state.tamanhoDoPrisma && this.state.tamanhoDoPrisma} onChange={(e) => this.setState({ tamanhoDoPrisma: e.target.value })}>
                                                                            <option value="normal">Normal</option>
                                                                            <option value="grande">Grande</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className='col-lg-12 my-2'>
                                                                        <span>Descrição do prisma para impressão</span>
                                                                        <input type="text" onChange={(e) => this.setState({ descricaoPrisma: e.target.value })} value={this.state.descricaoPrisma} className="form-control mt-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="setorEstacionamento" checked={this.state.setorEstacionamento} onChange={(e) => this.setState({ setorEstacionamento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="setorEstacionamento">
                                                            Habilitar campo de setor na entrada do veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="observacaoEstacionamento" checked={this.state.observacaoEstacionamento} onChange={(e) => this.setState({ observacaoEstacionamento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="observacaoEstacionamento">
                                                            Habilitar campo de observação na entrada do veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarCampoContato" checked={this.state.habilitarCampoContato} onChange={this.changeCamposContato} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarCampoContato">
                                                            Habilitar dados de contato na entrada do veículo.
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.habilitarCampoContato === true &&
                                                        <div className='col-lg-3 my-2'>
                                                            <button type="button" onClick={this.showCamposContatoModal} className="btn btn-success btn-sm">Editar campos de contato</button>
                                                        </div>
                                                    }
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarImprimirTabelaPrecoNoTicket" checked={this.state.habilitarImprimirTabelaPrecoNoTicket} onChange={(e) => this.setState({ habilitarImprimirTabelaPrecoNoTicket: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarImprimirTabelaPrecoNoTicket">
                                                            Imprimir a tabela de preço no ticket de entrada do veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarObrigatoriedadeAvaliacaoExterna" checked={this.state.habilitarObrigatoriedadeAvaliacaoExterna} onChange={(e) => this.setState({ habilitarObrigatoriedadeAvaliacaoExterna: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarObrigatoriedadeAvaliacaoExterna">
                                                            Obrigar o manobrista a fazer avaliação externa do veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarTipoDeAvariaAvaliacaoExterna" checked={this.state.habilitarTipoDeAvariaAvaliacaoExterna} onChange={(e) => this.setState({ habilitarTipoDeAvariaAvaliacaoExterna: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarTipoDeAvariaAvaliacaoExterna">
                                                            Solicitar tipo de avarias em avaliação externa.
                                                        </label>
                                                        <span className="badge text-bg-success mx-2">Novidade</span>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarBuscaDeMarcaEModeloOnline" checked={this.state.habilitarBuscaDeMarcaEModeloOnline} onChange={(e) => this.setState({ habilitarBuscaDeMarcaEModeloOnline: e.target.checked })} type="checkbox" />
                                                        <Tooltip title="Habilitando esta função o tempo de lançamento do veículo pode ser impactado." placement="top">
                                                            <label className="form-check-label" htmlFor="habilitarBuscaDeMarcaEModeloOnline">
                                                                Buscar online marcas e modelos já salvas associada a placa do veículo.
                                                            </label>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarObrigatoriedadeModeloCorDoVeiculoRapida" checked={this.state.habilitarObrigatoriedadeModeloCorDoVeiculoRapida} onChange={(e) => this.setState({ habilitarObrigatoriedadeModeloCorDoVeiculoRapida: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarObrigatoriedadeModeloCorDoVeiculoRapida">
                                                            Obrigar que o manobrista informe o modelo e cor do veículo quando digitado a placa ( na entrada rápida ).
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarObrigatoriedadeModeloCorDoVeiculo" checked={this.state.habilitarObrigatoriedadeModeloCorDoVeiculo} onChange={(e) => this.setState({ habilitarObrigatoriedadeModeloCorDoVeiculo: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarObrigatoriedadeModeloCorDoVeiculo">
                                                            Obrigar que o manobrista informe o modelo e cor do veículo quando digitado a placa.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarObrigatoriedadeModeloCorDoVeiculoPorFoto" checked={this.state.habilitarObrigatoriedadeModeloCorDoVeiculoPorFoto} onChange={(e) => this.setState({ habilitarObrigatoriedadeModeloCorDoVeiculoPorFoto: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarObrigatoriedadeModeloCorDoVeiculoPorFoto">
                                                            Obrigar que o manobrista informe o modelo e cor do veículo quando for tirado uma foto do veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarObrigatoriedadeTabelaDePrecoPorVeiculo" checked={this.state.habilitarObrigatoriedadeTabelaDePrecoPorVeiculo} onChange={(e) => this.setState({ habilitarObrigatoriedadeTabelaDePrecoPorVeiculo: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarObrigatoriedadeTabelaDePrecoPorVeiculo">
                                                            Obrigar que o manobrista selecione a tabela de preço sempre que for lançar um veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarDescontoManual" checked={this.state.habilitarDescontoManual} onChange={(e) => this.setState({ habilitarDescontoManual: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarDescontoManual">
                                                            Permitir adicionar desconto manual ( em pagamento de tickets)
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarDescontoManualMensalista" checked={this.state.habilitarDescontoManualMensalista} onChange={(e) => this.setState({ habilitarDescontoManualMensalista: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarDescontoManualMensalista">
                                                            Permitir adicionar desconto manual ( em pagamento de mensalistas )
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarDataEntrada" checked={this.state.habilitarDataEntrada} onChange={(e) => this.setState({ habilitarDataEntrada: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarDataEntrada">
                                                            Permitir editar o dia e horário de entrada do veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarDataSaida" checked={this.state.habilitarDataSaida} onChange={(e) => this.setState({ habilitarDataSaida: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarDataSaida">
                                                            Permitir editar o dia e horário de saída do veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarEdicaoAvaliacaoExternaNoTicket" checked={this.state.habilitarEdicaoAvaliacaoExternaNoTicket} onChange={(e) => this.setState({ habilitarEdicaoAvaliacaoExternaNoTicket: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarEdicaoAvaliacaoExternaNoTicket">
                                                            Permitir editar uma avaliação externa em tickets já lançados.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarEdicaoAvaliacaoInternaNoTicket" checked={this.state.habilitarEdicaoAvaliacaoInternaNoTicket} onChange={(e) => this.setState({ habilitarEdicaoAvaliacaoInternaNoTicket: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarEdicaoAvaliacaoInternaNoTicket">
                                                            Permitir editar uma avaliação interna em tickets já lançados.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarEdicaoPlacaNoTicket" checked={this.state.habilitarEdicaoPlacaNoTicket} onChange={(e) => this.setState({ habilitarEdicaoPlacaNoTicket: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarEdicaoPlacaNoTicket">
                                                            Permitir editar placa em tickets já lançados.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo" checked={this.state.habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo} onChange={(e) => this.setState({ habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarObrigatoriedadeCodigoAcessoLancamentoVeiculo">
                                                            Solicitar código de acesso ao lançar um novo veículo.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo" checked={this.state.habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo} onChange={(e) => this.setState({ habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarObrigatoriedadeCodigoAcessoPagamentoVeiculo">
                                                            Solicitar código de acesso ao fazer pagamento de um ticket.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarTicketBatch" checked={this.state.habilitarTicketBatch} onChange={(e) => this.setState({ habilitarTicketBatch: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarTicketBatch">
                                                            Permitir alterar status de tickets em lote ( mais de um por vez ).
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarMostrarCoresDosVeiculosNaListagem" checked={this.state.habilitarMostrarCoresDosVeiculosNaListagem} onChange={(e) => this.setState({ habilitarMostrarCoresDosVeiculosNaListagem: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarMostrarCoresDosVeiculosNaListagem">
                                                            Mostrar cores dos veículos na listagem.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem" checked={this.state.habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem} onChange={(e) => this.setState({ habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarMostrarTermpoDePermanenciaDosVeiculosNaListagem">
                                                            Mostrar tempo de permanência dos veículos na listagem.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarMostrarDetalhesDoTicketNoPagamento" checked={this.state.habilitarMostrarDetalhesDoTicketNoPagamento} onChange={(e) => this.setState({ habilitarMostrarDetalhesDoTicketNoPagamento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarMostrarDetalhesDoTicketNoPagamento">
                                                            Mostrar detalhes do ticket no momento do pagamento.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarCalculadoraAoSelecionarDinheiro" checked={this.state.habilitarCalculadoraAoSelecionarDinheiro} onChange={(e) => this.setState({ habilitarCalculadoraAoSelecionarDinheiro: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarCalculadoraAoSelecionarDinheiro">
                                                            Abrir calculadora ao selecionar a forma de pagamento "Dinheiro".
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarAssociacaoDeConvenioNaformaDePagamento" checked={this.state.habilitarAssociacaoDeConvenioNaformaDePagamento} onChange={(e) => this.setState({ habilitarAssociacaoDeConvenioNaformaDePagamento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarAssociacaoDeConvenioNaformaDePagamento">
                                                            Hábilitar convênio em formas de pagamentos.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarPatioAoVivo" checked={this.state.habilitarPatioAoVivo} onChange={(e) => this.setState({ habilitarPatioAoVivo: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarPatioAoVivo">
                                                            Hábilitar pátio ao vivo ( entrega de veículos por manobrista)
                                                        </label>
                                                        {/* <span className="badge text-bg-success mx-2">Novidade</span> */}
                                                    </div>
                                                    {
                                                        this.state.habilitarPatioAoVivo === true &&
                                                        <div className="col-lg-4 mt-3">
                                                            <AlertSecondary message="Tempo médio que o veículo fica no percurso até que seja entregue para o cliente.">
                                                                <div className="col-lg-4">
                                                                    <NumberFormat className="form-control" value={this.state.habilitarPatioAoVivoEmHoras} mask='_' onChange={(e) => this.setState({ habilitarPatioAoVivoEmHoras: e.target.value })} format="##:##" />
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarPatioAoVivoAoPagarEEntregarUmVeiculo" checked={this.state.habilitarPatioAoVivoAoPagarEEntregarUmVeiculo} onChange={(e) => this.setState({ habilitarPatioAoVivoAoPagarEEntregarUmVeiculo: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarPatioAoVivoAoPagarEEntregarUmVeiculo">
                                                                        Solicitar um veículo automaticamente ao ser pago e entregue.
                                                                    </label>
                                                                </div>
                                                            </AlertSecondary>
                                                        </div>
                                                    }
                                                    <div className="col-lg-4">
                                                        <label>Não listar tickets nos seguintes status no filtro "TODOS"</label>
                                                        <Select
                                                            isMulti
                                                            name="list_tickets"
                                                            value={this.state.excludedStatus}
                                                            options={this.state.allStatus}
                                                            onChange={this.handleExcludedStatus}
                                                            className="basic-multi-select"
                                                        />
                                                    </div>
                                                    <label className="my-2"> Tipo do teclado para lançamento de veículos </label>
                                                    <div className="col-lg-3">
                                                        <select className="form-select" value={this.state.typeKeyboard && this.state.typeKeyboard} onChange={(e) => this.setState({ typeKeyboard: e.target.value })}>
                                                            <option value="smart">Automatico</option>
                                                            <option value="qwerty">Manual</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        {
                                                            this.state.typeKeyboard === "smart" &&
                                                            <div className="mt-3">
                                                                <AlertSecondary message="O teclado muda conforme o operador vai digitando a placa para letras ou números." />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.typeKeyboard === "qwerty" &&
                                                            <div className="mt-3">
                                                                <AlertSecondary message="O teclado é apresentado com todas as letras e números ao mesmo momento ( QWERTY )." />
                                                            </div>
                                                        }
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="mensalista" title="Mensalista">
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarCobrancaMensalista" checked={this.state.habilitarCobrancaMensalista} onChange={(e) => this.setState({ habilitarCobrancaMensalista: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarCobrancaMensalista">
                                                            Avisar quando entrar um mensalista.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarCartaoParaAcesso" checked={this.state.habilitarCartaoParaAcesso} onChange={(e) => this.setState({ habilitarCartaoParaAcesso: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarCartaoParaAcesso">
                                                            Habilitar entrada via cartão de acesso para mensalistas e credenciados.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarTicketAvulsoParaMensalista" checked={this.state.habilitarTicketAvulsoParaMensalista} onChange={(e) => this.setState({ habilitarTicketAvulsoParaMensalista: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarTicketAvulsoParaMensalista">
                                                            Habilitar opção de associar um ticket avulso a um mensalista.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista" checked={this.state.habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista} onChange={(e) => this.setState({ habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarObrigacaoNoDiaEHorarioDoPlanoDeMensalista">
                                                            Não permitir que o mensalista entre fora do dia e/ou horário do plano.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarMensalistaImpressaoTicketEntrada" checked={this.state.habilitarMensalistaImpressaoTicketEntrada} onChange={(e) => this.setState({ habilitarMensalistaImpressaoTicketEntrada: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarMensalistaImpressaoTicketEntrada">
                                                            Imprimir ticket de entrada do mensalista.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarTarifaAposVencimento" checked={this.state.habilitarTarifaAposVencimento} onChange={(e) => this.setState({ habilitarTarifaAposVencimento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarTarifaAposVencimento">
                                                            Cobrar tarifa normalmente depois do vencimento.
                                                        </label>
                                                        {
                                                            this.state.habilitarTarifaAposVencimento === true &&
                                                            <div className="row">
                                                                <div className="col-lg-5 my-2">
                                                                    <select className="form-select" value={this.state.tarifaAposVencimenoDias && this.state.tarifaAposVencimenoDias} onChange={(e) => this.setState({ tarifaAposVencimenoDias: e.target.value })}>
                                                                        <option value="0">No mesmo dia</option>
                                                                        <option value="1">Após 1 dia</option>
                                                                        <option value="2">Após 2 dias</option>
                                                                        <option value="3">Após 3 dias</option>
                                                                        <option value="4">Após 4 dias</option>
                                                                        <option value="5">Após 5 dias</option>
                                                                        <option value="6">Após 6 dias</option>
                                                                        <option value="7">Após 7 dias</option>
                                                                        <option value="8">Após 8 dias</option>
                                                                        <option value="9">Após 9 dias</option>
                                                                        <option value="10">Após 10 dias</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarAcrecimoAposVencimento" checked={this.state.habilitarAcrecimoAposVencimento} onChange={(e) => this.setState({ habilitarAcrecimoAposVencimento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarAcrecimoAposVencimento">
                                                            Habilitar cobrança de acréscimo após vencimento.
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.habilitarAcrecimoAposVencimento === true &&
                                                        <div className="col-lg-12 row my-2">
                                                            <label className="my-2">Após </label>
                                                            <div className="col-lg-3">
                                                                <select className="form-select" value={this.state.acrecimoAposVencimentoDias && this.state.acrecimoAposVencimentoDias} onChange={(e) => this.setState({ acrecimoAposVencimentoDias: e.target.value })}>
                                                                    <option value="1">1 dia</option>
                                                                    <option value="2">2 dias</option>
                                                                    <option value="3">3 dias</option>
                                                                    <option value="4">4 dias</option>
                                                                    <option value="5">5 dias</option>
                                                                    <option value="6">6 dias</option>
                                                                    <option value="7">7 dias</option>
                                                                    <option value="8">8 dias</option>
                                                                    <option value="9">9 dias</option>
                                                                    <option value="10">10 dias</option>
                                                                </select>
                                                            </div>
                                                            <label className="my-2">de inadimplência, cobrar</label>
                                                            <div className="col-lg-2">
                                                                <input type="number" onChange={(e) => this.setState({ acrecimoAposVencimentoPorcentagem: e.target.value })} value={this.state.acrecimoAposVencimentoPorcentagem} className="form-control" />
                                                            </div>
                                                            <label className="my-2">% de acrécimo.</label>
                                                        </div>
                                                    }
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarDescontoAntesDoVencimento" checked={this.state.habilitarDescontoAntesDoVencimento} onChange={(e) => this.setState({ habilitarDescontoAntesDoVencimento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarDescontoAntesDoVencimento">
                                                            Habilitar desconto para pagamentos antes do vencimento.
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.habilitarDescontoAntesDoVencimento === true &&
                                                        <div className="col-lg-12 row my-2">
                                                            <label className="my-2">Até </label>
                                                            <div className="col-lg-3">
                                                                <select className="form-select" value={this.state.descontoAntesDoVencimentoDias && this.state.descontoAntesDoVencimentoDias} onChange={(e) => this.setState({ descontoAntesDoVencimentoDias: e.target.value })}>
                                                                    <option value="1">1 dia</option>
                                                                    <option value="2">2 dias</option>
                                                                    <option value="3">3 dias</option>
                                                                    <option value="4">4 dias</option>
                                                                    <option value="5">5 dias</option>
                                                                    <option value="6">6 dias</option>
                                                                    <option value="7">7 dias</option>
                                                                    <option value="8">8 dias</option>
                                                                    <option value="9">9 dias</option>
                                                                    <option value="10">10 dias</option>
                                                                </select>
                                                            </div>
                                                            <label className="my-2">de adiantamento, dar </label>
                                                            <div className="col-lg-2">
                                                                <input type="number" onChange={(e) => this.setState({ descontoAntesDoVencimentoPorcentagem: e.target.value })} value={this.state.descontoAntesDoVencimentoPorcentagem} className="form-control" />
                                                            </div>
                                                            <label className="my-2">% de desconto.</label>
                                                        </div>
                                                    }
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarMensagemInadimplente" checked={this.state.habilitarMensagemInadimplente} onChange={(e) => this.setState({ habilitarMensagemInadimplente: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarMensagemInadimplente">
                                                            Imprimir mensagem no ticket aos mensalistas inadimplentes.
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.habilitarMensagemInadimplente === true &&
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <input placeholder="Mensagem para mensalistas inadimplentes" type="text" onChange={(e) => this.setState({ mensagemMensalistaInadimplentes: e.target.value })} value={this.state.mensagemMensalistaInadimplentes} className="form-control" />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarRotacionarVagaMensalista" checked={this.state.habilitarRotacionarVagaMensalista} onChange={(e) => this.setState({ habilitarRotacionarVagaMensalista: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarRotacionarVagaMensalista">
                                                            Permitir rotacionar vaga de mensalista.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="permitirSaidaTemporariaMensalista" checked={this.state.permitirSaidaTemporariaMensalista} onChange={(e) => this.setState({ permitirSaidaTemporariaMensalista: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="permitirSaidaTemporariaMensalista">
                                                            Permitir que o mensalista retire o veículo do estabelecimento de forma temporárea.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarPagamentosDeMultiplasMensalidadesDoMensalista" checked={this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalista} onChange={(e) => this.setState({ habilitarPagamentosDeMultiplasMensalidadesDoMensalista: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarPagamentosDeMultiplasMensalidadesDoMensalista">
                                                            Permitir pagar mais de um mês de mensalidade em um pagamento.
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalista &&
                                                        <div className="col-lg-3">
                                                            <select className="form-select" value={this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalistaMeses && this.state.habilitarPagamentosDeMultiplasMensalidadesDoMensalistaMeses} onChange={(e) => this.setState({ habilitarPagamentosDeMultiplasMensalidadesDoMensalistaMeses: e.target.value })}>
                                                                <option value="2">Até 2 meses adiantado</option>
                                                                <option value="3">Até 3 meses adiantado</option>
                                                                <option value="4">Até 4 meses adiantado</option>
                                                                <option value="5">Até 5 meses adiantado</option>
                                                                <option value="6">Até 6 meses adiantado</option>
                                                                <option value="7">Até 7 meses adiantado</option>
                                                                <option value="8">Até 8 meses adiantado</option>
                                                                <option value="9">Até 9 meses adiantado</option>
                                                                <option value="10">Até 10 meses adiantado</option>
                                                                <option value="11">Até 11 meses adiantado</option>
                                                                <option value="12">Até 12 meses adiantado</option>
                                                            </select>
                                                        </div>
                                                    }
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarProdutosConsumidosNaProximaFatura" checked={this.state.habilitarProdutosConsumidosNaProximaFatura} onChange={(e) => this.setState({ habilitarProdutosConsumidosNaProximaFatura: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarProdutosConsumidosNaProximaFatura">
                                                            Permitir lançar produtos e serviços consumidos na próxima fatura da mensalidade.
                                                        </label>
                                                    </div>
                                                    <div className='my-2 col-lg-6'>
                                                        <AlertSecondary tag="Credenciados">
                                                            <div className="form-check form-switch my-2">
                                                                <input className="form-check-input" id="habilitarCredenciadosCadastrarViaLink" checked={this.state.habilitarCredenciadosCadastrarViaLink} onChange={(e) => this.setState({ habilitarCredenciadosCadastrarViaLink: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="habilitarCredenciadosCadastrarViaLink">
                                                                    Permitir que credenciado se cadastre via portal.
                                                                </label>
                                                            </div>
                                                        </AlertSecondary>
                                                    </div>
                                                    {/* <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarCompartilhamentoDeMensalistasNaRede" checked={this.state.habilitarCompartilhamentoDeMensalistasNaRede} onChange={(e) => this.setState({ habilitarCompartilhamentoDeMensalistasNaRede: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarCompartilhamentoDeMensalistasNaRede">
                                                            Permitir que o mensalista entre em outros estabelecimentos da rede sem emitir cobranças.
                                                        </label>
                                                    </div> */}
                                                </Tab>
                                                <Tab eventKey="impressao" title="Impressão">
                                                    <div className="alert alert-secondary my-3" role="alert">
                                                        <strong>Dados do estabelecimento no cabeçalho do ticket</strong>
                                                        {
                                                            this.state.habilitarPrintParkImage &&
                                                            <>
                                                                <div className="col-lg-3 mt-2">
                                                                    <div className="col-lg-12">
                                                                        <label htmlFor="formFile" className="form-label">Logo para impressão</label>
                                                                        <Tooltip title="Informe um logo nas medidas de 200 pixels de largura e 48 pixels de altura e preto e branco de preferência." placement="top">
                                                                            <i role='button' className="fas fa-info-circle mx-2" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <img src={this.state.logoParkPrinterExample} width={200} height={48} />
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <input type="file" accept="png" onChange={this.handlePrinterFileChange} id="formFile" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="form-check form-switch mt-3">
                                                            <input className="form-check-input" id="habilitarImpressaoDadosEstabelecimentoNome" checked={this.state.habilitarImpressaoDadosEstabelecimentoNome} onChange={(e) => this.setState({ habilitarImpressaoDadosEstabelecimentoNome: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarImpressaoDadosEstabelecimentoNome">
                                                                Imprimir nome do estabelecimento.
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.habilitarImpressaoDadosEstabelecimentoNome &&
                                                            <div className="col-lg-5">
                                                                <label className="form-check-label" htmlFor="tamanhoDoNomeDoEstacionamento">
                                                                    Tamanho do nome do estacionamento na impressão.
                                                                </label>
                                                                <select className="form-select" value={this.state.tamanhoDoNomeDoEstacionamento && this.state.tamanhoDoNomeDoEstacionamento} onChange={(e) => this.setState({ tamanhoDoNomeDoEstacionamento: e.target.value })}>
                                                                    <option value="normal">Normal</option>
                                                                    <option value="grande">Grande</option>
                                                                </select>
                                                            </div>
                                                        }
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="habilitarImpressaoDadosEstabelecimentoDocumento" checked={this.state.habilitarImpressaoDadosEstabelecimentoDocumento} onChange={(e) => this.setState({ habilitarImpressaoDadosEstabelecimentoDocumento: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarImpressaoDadosEstabelecimentoDocumento">
                                                                Imprimir documento.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento" checked={this.state.habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento} onChange={(e) => this.setState({ habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento">
                                                                Imprimir horário de funcionamento.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="habilitarImpressaoDadosEstabelecimentoEndereco" checked={this.state.habilitarImpressaoDadosEstabelecimentoEndereco} onChange={(e) => this.setState({ habilitarImpressaoDadosEstabelecimentoEndereco: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarImpressaoDadosEstabelecimentoEndereco">
                                                                Imprimir endereço.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="habilitarImpressaoDadosEstabelecimentoCelular" checked={this.state.habilitarImpressaoDadosEstabelecimentoCelular} onChange={(e) => this.setState({ habilitarImpressaoDadosEstabelecimentoCelular: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarImpressaoDadosEstabelecimentoCelular">
                                                                Imprimir celular de contato.
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <AlertSuccess message="Imprimir o ticket resumido gera economia de bobina e o cliente consegue acessar todas as informações do ticket online no celular.">
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="habilitarTicketResumido" checked={this.state.habilitarTicketResumido} onChange={(e) => this.setState({ habilitarTicketResumido: e.target.checked })} type="checkbox" />
                                                            <Tooltip title="Os dados que não serão impressos: Placa, Modelo, Marca, Cor Prisma, Setor, Tabela, Avaliação externas e Avaliação internas." placement="top">
                                                                <label className="form-check-label" htmlFor="habilitarTicketResumido">
                                                                    Imprimir ticket resumido.
                                                                </label>
                                                            </Tooltip>
                                                        </div>
                                                    </AlertSuccess>
                                                    <div className="alert alert-secondary my-3" role="alert">
                                                        <strong>Dados do corpo do ticket</strong>
                                                        <div className="col-lg-5">
                                                            <label className="form-check-label" htmlFor="imprimirTicketNoFormato">
                                                                Imprimir o número do ticket em:
                                                            </label>
                                                            <select className="form-select" value={this.state.imprimirTicketNoFormato && this.state.imprimirTicketNoFormato} onChange={(e) => this.setState({ imprimirTicketNoFormato: e.target.value })}>
                                                                <option value="None">Nenhum</option>
                                                                <option value="QRCode">QRCode</option>
                                                                <option value="BarCode">Código de barras</option>
                                                                <option value="Both">QRCode e Código de barras</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="datahabilitarNumeroImpressoNoTicket" checked={this.state.datahabilitarNumeroImpressoNoTicket} onChange={(e) => this.setState({ datahabilitarNumeroImpressoNoTicket: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="datahabilitarNumeroImpressoNoTicket">
                                                                Imprimir número do ticket de entrada e saída do veículo.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="habilitarMensagemImpressaNoTicket" checked={this.state.habilitarMensagemImpressaNoTicket} onChange={(e) => this.setState({ habilitarMensagemImpressaNoTicket: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarMensagemImpressaNoTicket">
                                                                Imprimir mensagem em todos os tickets.
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.habilitarMensagemImpressaNoTicket === true &&
                                                            <>
                                                                <div className="row my-2">
                                                                    <div className="col-lg-6">
                                                                        <input type="text" maxLength={60} placeholder='Mensagem que deseja imprimir no ticket' onChange={(e) => this.setState({ mensagemLancamentoTicket: e.target.value })} value={this.state.mensagemLancamentoTicket} className="form-control" />
                                                                    </div>
                                                                    <label className="my-2"> no </label>
                                                                    <div className="col-lg-3">
                                                                        <select className="form-select" value={this.state.mensagemLancamentoTicketLocal && this.state.mensagemLancamentoTicketLocal} onChange={(e) => this.setState({ mensagemLancamentoTicketLocal: e.target.value })}>
                                                                            <option value="comeco">Começo do ticket</option>
                                                                            <option value="fim">Fim do ticket</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="alert alert-secondary my-3" role="alert">
                                                                    <strong>Exemplo: </strong> <br /> Quando existe algum benefício ou aviso para <strong>TODOS</strong> os clientes.
                                                                </div>
                                                            </>
                                                        }
                                                        {
                                                            this.state.habilitarTicketResumido === false &&
                                                            <>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarMensagemQuandoSelecionadaNoTicket" checked={this.state.habilitarMensagemQuandoSelecionadaNoTicket} onChange={(e) => this.setState({ habilitarMensagemQuandoSelecionadaNoTicket: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarMensagemQuandoSelecionadaNoTicket">
                                                                        Definir mensagem personalizada para impressão no ticket quando selecionada.
                                                                    </label>
                                                                </div>
                                                                {
                                                                    this.state.habilitarMensagemQuandoSelecionadaNoTicket === true &&
                                                                    <>
                                                                        <div className="col-lg-12">
                                                                            <div className='row'>
                                                                                <label className="my-2"> Imprimir a mensagem no </label>
                                                                                <div className="col-lg-3">
                                                                                    <select className="form-select" value={this.state.mensagemQuandoSelecionadaNoTicketLocal && this.state.mensagemQuandoSelecionadaNoTicketLocal} onChange={(e) => this.setState({ mensagemQuandoSelecionadaNoTicketLocal: e.target.value })}>
                                                                                        <option value="comeco">Começo do ticket</option>
                                                                                        <option value="fim">Fim do ticket</option>
                                                                                    </select>
                                                                                </div>
                                                                                <label className="my-2"> com a fonte do titulo </label>
                                                                                <div className="col-lg-3">
                                                                                    <select className="form-select" value={this.state.tituloQuandoSelecionadaNoTicketTamanho && this.state.tituloQuandoSelecionadaNoTicketTamanho} onChange={(e) => this.setState({ tituloQuandoSelecionadaNoTicketTamanho: e.target.value })}>
                                                                                        <option value="normal">normal</option>
                                                                                        <option value="grande">grande</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row my-3">
                                                                            <div className="col-lg-6">
                                                                                <label className="my-2">Titulo da mensagem que deseja imprimir em alguns tickets</label>
                                                                                <input type="text" maxLength={40} placeholder='(Opcional)' onChange={(e) => this.setState({ tituloQuandoSelecionadaNoTicket: e.target.value })} value={this.state.tituloQuandoSelecionadaNoTicket} className="form-control" />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <label className="my-2">Mensagem que deseja imprimir em alguns tickets</label>
                                                                                <input type="text" maxLength={100} onChange={(e) => this.setState({ mensagemQuandoSelecionadaNoTicket: e.target.value })} value={this.state.mensagemQuandoSelecionadaNoTicket} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="alert alert-secondary my-3" role="alert">
                                                                            <strong>Exemplo: </strong> <br /> Quando existe algum benefício ou aviso apenas para <strong>ALGUNS</strong> clientes.
                                                                        </div>
                                                                    </>
                                                                }
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarImprimirTituloDocumentoNaoFiscal" checked={this.state.habilitarImprimirTituloDocumentoNaoFiscal} onChange={(e) => this.setState({ habilitarImprimirTituloDocumentoNaoFiscal: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarImprimirTituloDocumentoNaoFiscal">
                                                                        Imprimir texto "Não é documento fiscal" para tickets pagos sem emissão de nota de serviço.
                                                                    </label>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="equipamentos" title="Equipamentos">
                                                    <div className='row mt-3'>
                                                        <div className='col-lg-4'>
                                                            <div className="alert alert-info">
                                                                <span className="badge text-bg-info text-white">Totem de auto atendimento</span> <br />
                                                                <div>Dê autonomia ao seu cliente e permita auto atendimento. <a target="_blank" href={`https://api.whatsapp.com/send?phone=55${getRevenda()?.whatsAppSuporte?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll("_", "")}`}><strong>Saiba mais</strong></a> </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarTotem" checked={this.state.habilitarTotem} onChange={(e) => this.setState({ habilitarTotem: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarTotem">
                                                                        Habilitar auto atendimento
                                                                    </label>
                                                                </div>
                                                                {
                                                                    this.state.habilitarTotem &&
                                                                    <>
                                                                        <div className='col-lg-12'>
                                                                            <div className="form-check form-switch my-2">
                                                                                <input className="form-check-input" id="habilitarTotemCompraDeProdutos" checked={this.state.habilitarTotemCompraDeProdutos} onChange={(e) => this.setState({ habilitarTotemCompraDeProdutos: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="habilitarTotemCompraDeProdutos">
                                                                                    Permitir compra de produtos e serviços.
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-12'>
                                                                            <div className="form-check form-switch my-2">
                                                                                <input className="form-check-input" id="habilitarTotemPagamentoDeTicket" checked={this.state.habilitarTotemPagamentoDeTicket} onChange={(e) => this.setState({ habilitarTotemPagamentoDeTicket: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="habilitarTotemPagamentoDeTicket">
                                                                                    Permitir pagamento de ticket do estacionamento.
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='col-lg-12'>
                                                                            <div className="form-check form-switch my-2">
                                                                                <input className="form-check-input" id="habilitarTotemPagamentoDeMensalista" checked={this.state.habilitarTotemPagamentoDeMensalista} onChange={(e) => this.setState({ habilitarTotemPagamentoDeMensalista: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="habilitarTotemPagamentoDeMensalista">
                                                                                    Permitir pagamento de cobrança do mensalista.
                                                                                </label>
                                                                            </div>
                                                                        </div> */}
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <div className="alert alert-info">
                                                                <span className="badge text-bg-info text-white">Cancelas</span> <br />
                                                                <div>Automatize o seu estabelecimento com as cancelas mais modernas do mercado. <a target="_blank" href={`https://api.whatsapp.com/send?phone=55${getRevenda()?.whatsAppSuporte?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll("_", "")}`}><strong>Saiba mais</strong></a> </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarCancela" checked={this.state.habilitarCancela} onChange={(e) => this.setState({ habilitarCancela: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarCancela">
                                                                        Habilitar integração com cancelas
                                                                    </label>
                                                                </div>
                                                                {
                                                                    this.state.habilitarCancela &&
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarControlarVagas" checked={this.state.habilitarControlarVagas} onChange={(e) => this.setState({ habilitarControlarVagas: e.target.checked })} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarControlarVagas">
                                                                                Controlar quantidade de vagas do estacionamento.
                                                                            </label>
                                                                        </div>
                                                                        {
                                                                            this.state.habilitarControlarVagas && <>
                                                                                <div className="col-lg-12">
                                                                                    <div className='col-lg-5'>
                                                                                        <span className="my-2">Quantidade de vagas</span>
                                                                                        <input type="number" onChange={(e) => this.setState({ habilitarControlarVagasQuantidade: e.target.value })} value={this.state.habilitarControlarVagasQuantidade} className="form-control" />
                                                                                    </div>
                                                                                    <div className="form-check form-switch my-2">
                                                                                        <input className="form-check-input" id="habilitarEmergenciaNasCancelas" checked={this.state.habilitarEmergenciaNasCancelas} onChange={(e) => this.setState({ habilitarEmergenciaNasCancelas: e.target.checked })} type="checkbox" />
                                                                                        <label className="form-check-label" htmlFor="habilitarEmergenciaNasCancelas">
                                                                                            Permitir entrar todos os veículos sem validação.
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            !this.state.habilitarEmergenciaNasCancelas &&
                                                                            <div className="form-check form-switch my-2">
                                                                                <input className="form-check-input" id="habilitarControlarVagasBloquearEntrada" checked={this.state.habilitarControlarVagasBloquearEntrada} onChange={(e) => this.setState({ habilitarControlarVagasBloquearEntrada: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="habilitarControlarVagasBloquearEntrada">
                                                                                    Bloquear entrada de veículos caso chegue no limite de vagas disponíveis.
                                                                                </label>
                                                                            </div>
                                                                        }
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarReusoDeTicketImpressoNasCancelas" checked={this.state.habilitarReusoDeTicketImpressoNasCancelas} onChange={(e) => this.setState({ habilitarReusoDeTicketImpressoNasCancelas: e.target.checked })} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarReusoDeTicketImpressoNasCancelas">
                                                                                Permitir reuso de um número de ticket impresso.
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="integracoes" title="Integrações">
                                                    <div className='row'>
                                                        <div className="mt-3">
                                                            <AlertSecondary tag="Informação">
                                                                Caso tenha alguma dúvida sobre qualquer integração abaixo, entre em contato com o suporte <a target="_blank" href={`https://api.whatsapp.com/send?phone=55${getRevenda()?.whatsAppSuporte?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll("_", "")}`}><strong>Clicando aqui</strong></a> <br /><p />
                                                                <strong>Autenticação API</strong> <br />
                                                                <strong>X_API_KEY: </strong> {this.state.X_API_KEY} <a href='#' className='text-danger' onClick={this.showAPISettingsModal}><strong className='mx-2'>Editar</strong></a>
                                                            </AlertSecondary>
                                                        </div>
                                                        <div className="col-12 col-sm-3 col-lg-3 mt-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body">
                                                                    <div className='text-center'>
                                                                        <a href="https://www.semparar.com.br/" target="_blank">
                                                                            <img alt='SemParar' width='80%' height='50%' src={require('../../files/integracao/semparar.png')} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarSemParar" checked={this.state.habilitarSemParar} onChange={(e) => this.setState({ habilitarSemParar: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarSemParar">
                                                                            Receber pagamentos por Sem Parar
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarSemParar &&
                                                                        <div className="col-lg-12">
                                                                            <label>Código do estabelecimento</label>
                                                                            <input type="text" maxLength={100} onChange={(e) => this.setState({ codigoEstabelecimentoSemParar: e.target.value })} value={this.state.codigoEstabelecimentoSemParar} className="form-control" />
                                                                            <label>Hash do estabelecimento</label>
                                                                            <input type="text" maxLength={100} onChange={(e) => this.setState({ hashEstabelecimentoSemParar: e.target.value })} value={this.state.hashEstabelecimentoSemParar} className="form-control" />
                                                                            {
                                                                                this.state.enviarTicketSemParar &&
                                                                                <div className="alert alert-success my-2" role="alert">
                                                                                    <strong>Atenção:</strong> Este recurso pode gerar cobranças adicionais no seu plano, converse com o seu representante de vendas caso tenha dúvidas.
                                                                                </div>
                                                                            }
                                                                            {
                                                                                isOwner() &&
                                                                                <div className='alert alert-danger mt-3'>
                                                                                    <h6>Visível apenas para administradores</h6>
                                                                                    <small>Use os dados abaixo em ambiente de desenvolvimento caso queira testar.</small><p />
                                                                                    <strong>Código do estabelecimento:</strong><br />
                                                                                    05016<p />

                                                                                    <strong>Hash do estabelecimento:</strong><br />
                                                                                    033a6b711b7b2dd004190fe1f54bcbb2<p />

                                                                                    <strong>Placas:</strong><br />
                                                                                    QOL7486<br />
                                                                                    FOY1598<br /><p />

                                                                                    <strong>Suporte produção</strong> <p />
                                                                                    ingrid.aricia@semparar.com.br<br />
                                                                                    11 97053-7679
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-3 col-lg-3 mt-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body">
                                                                    <div className='text-center'>
                                                                        <a href="https://www.taggy.com.br" target="_blank">
                                                                            <img alt='Taggy' width='80%' height='50%' src={require('../../files/integracao/taggy.png')} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarTaggy" checked={this.state.habilitarTaggy} onChange={(e) => this.setState({ habilitarTaggy: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarTaggy">
                                                                            Receber pagamentos via Taggy
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarTaggy &&
                                                                        <div className="col-lg-12">
                                                                            <label>Código do estabelecimento</label>
                                                                            <input type="text" maxLength={100} onChange={(e) => this.setState({ codigoEstabelecimentoTaggy: e.target.value })} value={this.state.codigoEstabelecimentoTaggy} className="form-control" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-3 col-lg-3 mt-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body">
                                                                    <div className='text-center'>
                                                                        <a href="https://conectcar.com/" target="_blank">
                                                                            <img alt='ConectCar' width='80%' height='50%' src={require('../../files/integracao/conectcar.png')} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-check form-switch mt-3">
                                                                        <input className="form-check-input" id="habilitarConectCar" checked={this.state.habilitarConectCar} onChange={(e) => this.setState({ habilitarConectCar: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarConectCar">
                                                                            Receber pagamentos via ConectCar
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarConectCar &&
                                                                        <div className="col-lg-12">
                                                                            <label>Código do estabelecimento</label>
                                                                            <input type="text" maxLength={100} onChange={(e) => this.setState({ codigoEstabelecimentoConectCar: e.target.value })} value={this.state.codigoEstabelecimentoConectCar} className="form-control" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-3 col-lg-3 mt-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body">
                                                                    <div className='text-center'>
                                                                        <a href="https://veloe.com.br/" target="_blank">
                                                                            <img alt='Logo Sênior' width='80%' height='50%' src={require('../../files/integracao/veloe.png')} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-check form-switch mt-3">
                                                                        <input className="form-check-input" id="habilitarVeloe" checked={this.state.habilitarVeloe} onChange={(e) => this.setState({ habilitarVeloe: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarVeloe">
                                                                            Receber pagamentos via Veloe
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarVeloe &&
                                                                        <div className="col-lg-12">
                                                                            <label>Código do estabelecimento</label>
                                                                            <input type="text" onChange={(e) => this.setState({ codigoEstabelecimentoVeloe: e.target.value })} value={this.state.codigoEstabelecimentoVeloe} className="form-control" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-3 col-lg-3 mt-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body">
                                                                    <div className='text-center'>
                                                                        <a href="https://senior.com.br/" target="_blank">
                                                                            <img alt='Logo Sênior' width='80%' height='50%' src={require('../../files/integracao/senior.png')} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarSeniorERP" checked={this.state.habilitarSeniorERP} onChange={(e) => this.setState({ habilitarSeniorERP: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarSeniorERP">
                                                                            Habilitar Sênior
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarSeniorERP &&
                                                                        <div className='col-lg-12'>
                                                                            {
                                                                                this.state.idBranchSenior ?
                                                                                    <button type="button" onClick={this.showSeniorSettingsModal} className="btn btn-warning text-white mt-2 text-white w-100">Editar configuração</button>
                                                                                    :
                                                                                    <button type="button" onClick={this.showSeniorSettingsModal} className="btn btn-success mt-2 w-100">Configurar</button>
                                                                            }
                                                                            {
                                                                                isAdministrador() && <button type="button" onClick={this.showResyncSeniorModal} className="btn btn-danger mt-2 w-100">Sincronizar pagamentos</button>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-3 col-lg-3 mt-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body">
                                                                    <div className='text-center'>
                                                                        <a href="https://f360.com.br/" target="_blank">
                                                                            <img alt='Logo F360' width='80%' height='50%' src={require('../../files/integracao/F360.png')} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarF360ERP" checked={this.state.habilitarF360ERP} onChange={(e) => this.setState({ habilitarF360ERP: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarF360ERP">
                                                                            Habilitar F360
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarF360ERP &&
                                                                        <>
                                                                            <div className="col-lg-12">
                                                                                <label>Identificador único do serviço</label>
                                                                                <input type="text" onChange={(e) => this.setState({ identificadorUnicoServicoF360: e.target.value })} value={this.state.identificadorUnicoServicoF360} className="form-control" />
                                                                            </div>
                                                                            <div className="col-lg-12">
                                                                                <label>CNPJ</label>
                                                                                <NumberFormat className="form-control" mask='_' format={'##.###.###/####-##'} value={this.state.documentF360} onChange={(e) => this.setState({ documentF360: e.target.value })} />
                                                                            </div>
                                                                            <div className="col-lg-12 mt-3">
                                                                                <AlertWarning message="Caso não preencha o CNPJ, o sistema irá utilizar o CNPJ cadastrado nos dados do estabelecimento." />
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-3 col-lg-3 mt-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body">
                                                                    <div className='text-center'>
                                                                        <a href="https://garcomweb.com.br/" target="_blank">
                                                                            <img alt='Garçom Web' width='80%' height='50%' src={require('../../files/integracao/garcomweb.png')} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarGarcomWeb" checked={this.state.habilitarGarcomWeb} onChange={(e) => this.setState({ habilitarGarcomWeb: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarGarcomWeb">
                                                                            Habilitar Garçom Web
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarGarcomWeb &&
                                                                        <div className="col-lg-12">
                                                                            <label>Código do estabelecimento</label>
                                                                            <input type="text" maxLength={100} onChange={(e) => this.setState({ X_GW_EC: e.target.value })} value={this.state.X_GW_EC} className="form-control" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-12 col-sm-3 col-lg-3 mt-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body">
                                                                    <div className='text-center'>
                                                                        <a href="https://zanthus.com.br/" target="_blank">
                                                                            <img alt='Garçom Web' width='80%' height='50%' src={require('../../files/integracao/zanthus.png')} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarPDVZanthus" checked={this.state.habilitarPDVZanthus} onChange={(e) => this.setState({ habilitarPDVZanthus: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarPDVZanthus">
                                                                            Habilitar Zanthus
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-12 col-sm-3 col-lg-3">
                                                            <div className="card text-secondary bg-white">
                                                                <div className="card-body text-center">
                                                                    <img alt='Logo SemParar' width='40%' height='40%' src={require('../../files/integracao/autoatendimento.jpeg')} />
                                                                    <div className="form-check text-left my-2">
                                                                        <input className="form-check-input" id="habilitarAutoAtendimento" checked={this.state.habilitarAutoAtendimento} onChange={(e) => this.setState({ habilitarAutoAtendimento: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarAutoAtendimento">
                                                                            Habilitar auto atendimento
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="sistema" title="Sistema">
                                                    <div className='row'>
                                                        <div className="col-12 col-sm-3 col-lg-3 my-3">
                                                            <label>Fuso horário</label>
                                                            <div className="input-group mb-3">
                                                                <select className="form-select" value={this.state.timezone && this.state.timezone} onChange={(e) => this.setState({ timezone: e.target.value })} required>
                                                                    <option value=''>Automático</option>
                                                                    {
                                                                        this.state.timezones.map(item => <option key={item.value} value={item.value}>{item.name}</option>)
                                                                    }
                                                                </select>
                                                            </div>
                                                            {
                                                                this.state.timezone === '' ?
                                                                    <AlertWarning>
                                                                        Será selecionado o fuso horário do aparelho em uso ( é preciso deixar configurado o mesmo fuso horario nos aparelhos ).
                                                                    </AlertWarning> : <AlertWarning>
                                                                        Este será o fuso horário do aplicativo no aparelho ( mesmo que o aparelho use outro fuso horário, dentro do aplicativo SelfParking será selecionado o acima ).
                                                                    </AlertWarning>
                                                            }
                                                        </div>
                                                    </div>
                                                </Tab>
                                                {
                                                    isAdministrador() &&
                                                    <Tab eventKey="administrativo" title="Administrativo">
                                                        <div className='mt-2'>
                                                            <AlertDanger message="Apenas usuários administradores conseguem visualizar esta aba.">
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="privadoHabilitarPermitirEnviarSMS" checked={this.state.privadoHabilitarPermitirEnviarSMS} onChange={(e) => this.sendSMSMessage(e.target.checked)} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="privadoHabilitarPermitirEnviarSMS">
                                                                        Permitir enviar os tickets por SMS.
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="privadoHabilitarPermitirEnviarWhatsApp" checked={this.state.privadoHabilitarPermitirEnviarWhatsApp} onChange={(e) => this.sendWhatsAppMessage(e.target.checked)} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="privadoHabilitarPermitirEnviarWhatsApp">
                                                                        Permitir enviar os tickets por WhatsApp.
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="printCustomImage" checked={this.state.printCustomImage} onChange={(e) => this.setState({ printCustomImage: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="printCustomImage">
                                                                        Não imprimir logo do sistema no ticket
                                                                    </label>
                                                                </div>
                                                                {
                                                                    this.state.printCustomImage &&
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarPrintParkImage" checked={this.state.habilitarPrintParkImage} onChange={(e) => this.setState({ habilitarPrintParkImage: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarPrintParkImage">
                                                                            Permitir o estabelecimento a selecionar um logo próprio.
                                                                        </label>
                                                                    </div>
                                                                }
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="printWebSite" checked={this.state.printWebSite} onChange={(e) => this.setState({ printWebSite: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="printWebSite">
                                                                        Imprimir site no fim do ticket.
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="privadoHabilitarNovaCameraAndroid" checked={this.state.privadoHabilitarNovaCameraAndroid} onChange={(e) => this.setState({ privadoHabilitarNovaCameraAndroid: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="privadoHabilitarNovaCameraAndroid">
                                                                        Usar nova camera do Android
                                                                    </label>
                                                                </div>
                                                                <label className="my-2">Algoritmo de impressão</label>
                                                                <span className="badge text-bg-secondary text-white mx-2">Cielo</span>
                                                                <span className="badge text-bg-secondary text-white">GetNet</span>
                                                                <span className="badge text-bg-secondary text-white mx-2">Rede | L400</span>
                                                                <div className="col-lg-3">
                                                                    <select className="form-select" value={this.state.algoritmoDeImpressao && this.state.algoritmoDeImpressao} onChange={(e) => this.setState({ algoritmoDeImpressao: e.target.value })}>
                                                                        <option value="PRINT_BITMAP">Imagem inteira</option>
                                                                        <option value="PRINT_SPLIT_IMAGE">Quebrar imagem</option>
                                                                        <option value="PRINT_TEXT">Em formato de texto</option>
                                                                    </select>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarOnlineCalculation" checked={this.state.habilitarOnlineCalculation} onChange={(e) => this.setState({ habilitarOnlineCalculation: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarOnlineCalculation">
                                                                        Calcular tabela de preço online
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarLogsAplicativo" checked={this.state.habilitarLogsAplicativo} onChange={(e) => this.setState({ habilitarLogsAplicativo: e.target.checked })} type="checkbox" />
                                                                    <Tooltip title="Habilite apenas quando for necessário entender as navegações do operador. NÃO DEIXE HABILITADO SEMPRE" placement="top">
                                                                        <label className="form-check-label" htmlFor="habilitarLogsAplicativo">
                                                                            Habilitar logs de navegação do aplicativo
                                                                        </label>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarObrigacaoParaAtualizarOsParametros" checked={this.state.habilitarObrigacaoParaAtualizarOsParametros} onChange={(e) => this.setState({ habilitarObrigacaoParaAtualizarOsParametros: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarObrigacaoParaAtualizarOsParametros">
                                                                        Obrigar atualização de tabelas ao abrir o aplicativo quando o sincronismo for interrompido.
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="habilitarOCRNaFotoDoVeiculo" checked={this.state.habilitarOCRNaFotoDoVeiculo} onChange={(e) => this.setState({ habilitarOCRNaFotoDoVeiculo: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarOCRNaFotoDoVeiculo">
                                                                        Habilitar OCR na foto da placa do veículo
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="bloquearEntradaAvulso" checked={this.state.bloquearEntradaAvulso} onChange={(e) => this.setState({ bloquearEntradaAvulso: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="bloquearEntradaAvulso">
                                                                        Não permitir entrada de veiculos avulsos.
                                                                    </label>
                                                                </div>
                                                                <label>Quantidade máxima de fotos externas</label>
                                                                <div className='col-lg-3'>
                                                                    <input type="number" onChange={(e) => this.setState({ quantidadeDeFotosAvaliacaoExterna: e.target.value })} value={this.state.quantidadeDeFotosAvaliacaoExterna} className="form-control" />
                                                                </div>
                                                            </AlertDanger>
                                                        </div>
                                                    </Tab>
                                                }
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div className='btn-group'>
                                        <button type="button" onClick={this.update} className="btn btn-primary">Atualizar</button>
                                        {
                                            isAdministrador() &&
                                            <button type="button" onClick={this.updateAll} className="btn btn-danger">Atualizar todos</button>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <Dialog onClose={this.hideAPISettingsModal} fullWidth maxWidth={"xs"} open={this.state.isShowAPISettingsModal}>
                        <MuiDialogTitle className='text-center'>
                            Editar chave de acesso
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className="col-lg-12">
                                <div className='alert alert-warning'>
                                    <strong>Atenção</strong>
                                    {
                                        this.state.X_API_KEY_TYPE === "guid" && <span> Maior segurança na integração com outros parceiros</span>
                                    }
                                    {
                                        this.state.X_API_KEY_TYPE === "number" && <span> Menor segurança na integração com outros parceiros.</span>
                                    }
                                    {
                                        this.state.X_API_KEY_TYPE === "open" && <span> Menor segurança na integração com outros parceiros e é preciso ter ao menos 10 digitos.</span>
                                    }
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <select className="form-select" value={this.state.X_API_KEY_TYPE} onChange={(e) => this.changeAPIKeyType(e)}>
                                        <option value="guid">GUID</option>
                                        <option value="number">Numérico</option>
                                        <option value="open">Campo aberto</option>
                                    </select>
                                </div>
                                {
                                    this.state.X_API_KEY_TYPE === "open" ?
                                        <>
                                            <label>Digite a nova chave de API desejada</label>
                                            <input type="text" onChange={(e) => this.setState({ X_API_KEY_TEMP: e.target.value })} value={this.state.X_API_KEY_TEMP} className="form-control" />
                                        </> : <>
                                            <strong>X_API_KEY</strong> <br />
                                            <span>{this.state.X_API_KEY_TEMP ?? this.state.X_API_KEY}</span>
                                        </>
                                }
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={this.hideAPISettingsModal} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.saveNewXAPIKEY} className='btn btn-success'>Salvar configurações</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog onClose={this.hideSeniorSettingsModal} fullWidth open={this.state.isShowSeniorSettingsModal}>
                        <MuiDialogTitle className='text-center'>
                            Configurações da Sênior X
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className="col-lg-12">
                                {
                                    this.state.seniorCodeNotAdded &&
                                    <div className='alert alert-warning'>
                                        <strong>Atenção </strong> Existe uma ou mais Formas de pagamento sem o código de pagamento da Sênior, isso pode gerar inconsistencias nas sincronizações dos pagamentos. <p />
                                        <a href='/cadastrar/forma-pagamento' className="btn btn-warning text-white" target='_blank'>Cadastrar códigos</a>
                                    </div>
                                }
                                <label>Access Key</label>
                                <input type="password" onChange={(e) => this.setState({ accessKeySenior: e.target.value })} value={this.state.accessKeySenior} className="form-control" />
                                <label>Secret</label>
                                <input type="password" onChange={(e) => this.setState({ secretSenior: e.target.value })} value={this.state.secretSenior} className="form-control" />
                                <label>Tenant Name</label>
                                <input type="text" onChange={(e) => this.setState({ tenantNameSenior: e.target.value })} value={this.state.tenantNameSenior} className="form-control" />
                                <label>Identificador da empresa</label>
                                <input type="text" onChange={(e) => this.setState({ idCompanySenior: e.target.value })} value={this.state.idCompanySenior} className="form-control" />
                                <label>Identificador da filial</label>
                                <input type="text" onChange={(e) => this.setState({ idBranchSenior: e.target.value })} value={this.state.idBranchSenior} className="form-control" />
                                <label>Identificador do cliente</label>
                                <input type="text" onChange={(e) => this.setState({ idCustomerSenior: e.target.value })} value={this.state.idCustomerSenior} className="form-control" />
                                <label>Código do serviço</label>
                                <input type="text" onChange={(e) => this.setState({ idItemServiceSenior: e.target.value })} value={this.state.idItemServiceSenior} className="form-control" />
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={this.hideSeniorSettingsModal} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.saveSeniorSettings} className='btn btn-success'>Salvar configurações</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog fullWidth open={this.state.isShowResyncSeniorModal} maxWidth={'xs'}>
                        {
                            this.state.isShowResyncSeniorLoading ?
                                <>
                                    <MuiDialogTitle className='text-center'>
                                        Começamos o sincronismo de todos os pagamentos deste estabelecimento para a sênior, você pode navegar no site até que o sincronismo seja concluído.
                                    </MuiDialogTitle>
                                    <MuiDialogContent>
                                        <div className="text-center">
                                            <Loading />
                                        </div>
                                    </MuiDialogContent>
                                    <MuiDialogActions>
                                        <div className='btn-group m-3'>
                                            <button onClick={this.hideResyncSeniorModal} className='btn btn-secondary'>Fechar</button>
                                        </div>
                                    </MuiDialogActions>
                                </> : <>
                                    <MuiDialogTitle className='text-center'>
                                        Deseja mesmo sincronizar os dados de pagamentos com a sênior?
                                    </MuiDialogTitle>
                                    <MuiDialogContent>
                                        <div className="col-lg-12 text-center">
                                            <h6>Será sincronizado todos os pagagamentos nunca sincronizados.</h6>
                                        </div>
                                    </MuiDialogContent>
                                    <MuiDialogActions>
                                        <div className='btn-group m-3'>
                                            <button onClick={this.hideResyncSeniorModal} className='btn btn-secondary'>Fechar</button>
                                            <button onClick={this.confirmResyncSenior} className='btn btn-danger'>Confirmar sincronismo</button>
                                        </div>
                                    </MuiDialogActions>
                                </>
                        }
                    </Dialog>
                    <Dialog fullWidth open={this.state.isShowCamposContatosModal} maxWidth={'sm'}>
                        <MuiDialogTitle className='text-center'>
                            Dados de cadastro do motorista
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <AlertInfo message="Os dados serão salvos e associados a placa do veículo." />
                            <div className='row'>
                                <AlertSecondary tag="Solicitar o preenchimento dos campos abaixo">
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoNome" checked={this.state.habilitarCampoContatoNome} onChange={(e) => this.setState({ habilitarCampoContatoNome: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoNome">
                                                    Nome do motorista
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoNome &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoNomeObrigatorio" checked={this.state.habilitarCampoContatoNomeObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoNomeObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoNomeObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoNomeEmpresa" checked={this.state.habilitarCampoContatoNomeEmpresa} onChange={(e) => this.setState({ habilitarCampoContatoNomeEmpresa: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoNomeEmpresa">
                                                    Nome da empresa
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoNomeEmpresa &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoNomeEmpresaObrigatorio" checked={this.state.habilitarCampoContatoNomeEmpresaObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoNomeEmpresaObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoNomeObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoCelular" checked={this.state.habilitarCampoContatoCelular} onChange={(e) => this.setState({ habilitarCampoContatoCelular: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoCelular">
                                                    Celular
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoCelular &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoCelularObrigatorio" checked={this.state.habilitarCampoContatoCelularObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoCelularObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoCelularObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoEmail" checked={this.state.habilitarCampoContatoEmail} onChange={(e) => this.setState({ habilitarCampoContatoEmail: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoEmail">
                                                    E-mail
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoEmail &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoEmailObrigatorio" checked={this.state.habilitarCampoContatoEmailObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoEmailObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoEmailObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoTelefone" checked={this.state.habilitarCampoContatoTelefone} onChange={(e) => this.setState({ habilitarCampoContatoTelefone: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoTelefone">
                                                    Telefone ou ramal
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoTelefone &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoTelefoneObrigatorio" checked={this.state.habilitarCampoContatoTelefoneObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoTelefoneObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoTelefoneObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoDocumento" checked={this.state.habilitarCampoContatoDocumento} onChange={(e) => this.setState({ habilitarCampoContatoDocumento: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoDocumento">
                                                    CPF ou CNPJ
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoDocumento &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoDocumentoObrigatorio" checked={this.state.habilitarCampoContatoDocumentoObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoDocumentoObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoDocumentoObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoRG" checked={this.state.habilitarCampoContatoRG} onChange={(e) => this.setState({ habilitarCampoContatoRG: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoRG">
                                                    RG
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoRG &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoRGObrigatorio" checked={this.state.habilitarCampoContatoRGObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoRGObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoRGObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoDepartamento" checked={this.state.habilitarCampoContatoDepartamento} onChange={(e) => this.setState({ habilitarCampoContatoDepartamento: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoDepartamento">
                                                    Departamento
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoDepartamento &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoDepartamentoObrigatorio" checked={this.state.habilitarCampoContatoDepartamentoObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoDepartamentoObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoDepartamentoObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoObservacao" checked={this.state.habilitarCampoContatoObservacao} onChange={(e) => this.setState({ habilitarCampoContatoObservacao: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoObservacao">
                                                    Observação
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoObservacao &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoObservacaoObrigatorio" checked={this.state.habilitarCampoContatoObservacaoObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoObservacaoObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoObservacaoObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoMarcaModelo" checked={this.state.habilitarCampoContatoMarcaModelo} onChange={(e) => this.setState({ habilitarCampoContatoMarcaModelo: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoMarcaModelo">
                                                    Marca e modelo
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoMarcaModelo &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoMarcaModeloObrigatorio" checked={this.state.habilitarCampoContatoMarcaModeloObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoMarcaModeloObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoMarcaModeloObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoFotoMotorista" checked={this.state.habilitarCampoContatoFotoMotorista} onChange={(e) => this.setState({ habilitarCampoContatoFotoMotorista: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoFotoMotorista">
                                                    Foto do motorista
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoFotoMotorista &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoFotoMotoristaObrigatorio" checked={this.state.habilitarCampoContatoFotoMotoristaObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoFotoMotoristaObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoFotoMotoristaObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-lg-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarCampoContatoFotoHabilitacao" checked={this.state.habilitarCampoContatoFotoHabilitacao} onChange={(e) => this.setState({ habilitarCampoContatoFotoHabilitacao: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCampoContatoFotoHabilitacao">
                                                    Foto da habilitação
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {
                                                this.state.habilitarCampoContatoFotoHabilitacao &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarCampoContatoFotoHabilitacaoObrigatorio" checked={this.state.habilitarCampoContatoFotoHabilitacaoObrigatorio} onChange={(e) => this.setState({ habilitarCampoContatoFotoHabilitacaoObrigatorio: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCampoContatoFotoHabilitacaoObrigatorio">
                                                        Obrigatório
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </AlertSecondary>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={(e) => this.setState({ isShowCamposContatosModal: false })} className='btn btn-success'>Confirmar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    {
                        this.state.isShowCashierCategoriesModal && <MovementCategoriesCashierModal onClose={this.onCloseMovementCategories} />
                    }
                </NavBar >
            </>
        )
    }
}

class ListarParametrosCobrancas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: props.estacionamentoId ?? getEstacionamento()?.id,
            habilitarPagamentoMensalistasOnline: false,
            cobrancaMensalistaFormaAlertaAntesDoVencimento: "3",
            cobrancaMensalistaFormaAlertaAposVencimento: "3",
            cobrancaMensalistaFormaDePagamentoPixId: null,
            cobrancaMensalistaFormaDePagamentoCreditId: null,
            cobrancaMensalistaFormaDePagamentoPixGatewayHabilitado: false,
            cobrancaMensalistaFormaDePagamentoCreditGatewayHabilitado: false,
            cobrancaMensalistaFormaDePagamentoCreditGateway: "",
            cobrancaMensalistaFormaDePagamentoPixGateway: "",
            cobrancaMensalistaTemplateAntesDoVencimento: null,
            cobrancaMensalistaTemplateAposVencimento: null,
            cobrancaMensalistaTemplateNoPagamento: null,
            cobrancaMensalistaTemplateNoCadastro: null,
            cobrancaMensalistaFormaDePagamentoCreditGateway: null,
            habilitarCobrancaMensalistaEmissaoDeNotasTodosOsPagamentos: false,
            habilitarCobrancaMensalistaPermitirMudarDocumento: false,
            habilitarPagamentoTicketsOnline: false,
            cobrancaTicketFormaDePagamentoPixGateway: false,
            cobrancaTicketFormaDePagamentoPixGatewayHabilitado: false,
            cobrancaTicketFormaDePagamentoPixId: false,
            paymentPixMethods: [],
            paymentCreditMethods: [],
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const tab = queryString("tab")
        this.changeTab(tab ?? "mensalista")
        await this.loadParameters()
    }

    changeTab = (tab) => {
        setPathWindow(`/configuracao/cobrancas?tab=${tab}`)
        this.setState({ key: tab })
    }

    loadParameters = async () => {
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ ...parameter })
        this.setState({ habilitarDescontoManual: parameter.habilitarDescontoManual ?? false })
        const paymentMethods = await getFormasPagamentosAtivas({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ paymentPixMethods: paymentMethods.filter(e => e.tipo === "Pix") })
        this.setState({ paymentCreditMethods: paymentMethods.filter(e => e.tipo === "Crédito") })

        const park = await getParkById({ id: this.state.estacionamentoId })
        this.setState({ park: park })
        this.setState({ isLoading: false })

        const resale = await getRevendaById({ id: park.revendaId })
        this.setState({ cobrancaMensalistaTemplateAntesDoVencimentoPlaceholder: resale.cobrancaMensalistaTemplateAntesDoVencimento })
        this.setState({ cobrancaMensalistaTemplateAposVencimentoPlaceholder: resale.cobrancaMensalistaTemplateAposVencimento })
        this.setState({ cobrancaMensalistaTemplateNoPagamentoPlaceholder: resale.cobrancaMensalistaTemplateNoPagamento })
        this.setState({ cobrancaMensalistaTemplateNoCadastroPlaceholder: resale.cobrancaMensalistaTemplateNoCadastro })
        this.setState({ templateRespostaAutomaticaWhatsAppPlaceholder: resale.templateRespostaAutomaticaWhatsApp })
    }

    update = async () => {
        const data = {
            cobrancaMensalistaFormaAlertaAntesDoVencimento: this.state.cobrancaMensalistaFormaAlertaAntesDoVencimento,
            cobrancaMensalistaFormaAlertaAposVencimento: this.state.cobrancaMensalistaFormaAlertaAposVencimento,
            habilitarTarifaAposVencimento: this.state.habilitarTarifaAposVencimento,
            tarifaAposVencimenoDias: this.state.tarifaAposVencimenoDias,
            habilitarMensagemInadimplente: this.state.habilitarMensagemInadimplente,
            mensagemMensalistaInadimplentes: this.state.mensagemMensalistaInadimplentes,
            habilitarDescontoAntesDoVencimento: this.state.habilitarDescontoAntesDoVencimento,
            descontoAntesDoVencimentoPorcentagem: this.state.descontoAntesDoVencimentoPorcentagem,
            descontoAntesDoVencimentoDias: this.state.descontoAntesDoVencimentoDias,
            habilitarAcrecimoAposVencimento: this.state.habilitarAcrecimoAposVencimento,
            acrecimoAposVencimentoPorcentagem: this.state.acrecimoAposVencimentoPorcentagem,
            acrecimoAposVencimentoDias: this.state.acrecimoAposVencimentoDias,
            cobrancaMensalistaTemplateAntesDoVencimento: takeIfIsNotNullOrEmpty(this.state.cobrancaMensalistaTemplateAntesDoVencimento),
            cobrancaMensalistaTemplateAposVencimento: takeIfIsNotNullOrEmpty(this.state.cobrancaMensalistaTemplateAposVencimento),
            cobrancaMensalistaTemplateNoPagamento: takeIfIsNotNullOrEmpty(this.state.cobrancaMensalistaTemplateNoPagamento),
            cobrancaMensalistaTemplateNoCadastro: takeIfIsNotNullOrEmpty(this.state.cobrancaMensalistaTemplateNoCadastro),
            templateRespostaAutomaticaWhatsApp: takeIfIsNotNullOrEmpty(this.state.templateRespostaAutomaticaWhatsApp),
            habilitarPagamentoMensalistasOnline: this.state.habilitarPagamentoMensalistasOnline,
            pagamentoOnlineGatewaySafe2Pay: this.state.pagamentoOnlineGatewaySafe2Pay,
            pagamentoOnlineGatewayShipay: this.state.pagamentoOnlineGatewayShipay,
            cobrancaMensalistaFormaDePagamentoPixGatewayHabilitado: this.state.cobrancaMensalistaFormaDePagamentoPixGatewayHabilitado,
            cobrancaMensalistaFormaDePagamentoCreditGatewayHabilitado: this.state.cobrancaMensalistaFormaDePagamentoCreditGatewayHabilitado,
            cobrancaMensalistaFormaDePagamentoPixGateway: this.state.cobrancaMensalistaFormaDePagamentoPixGateway,
            cobrancaMensalistaFormaDePagamentoCreditGateway: this.state.cobrancaMensalistaFormaDePagamentoCreditGateway,
            cobrancaMensalistaFormaDePagamentoPixId: this.state.cobrancaMensalistaFormaDePagamentoPixId,
            cobrancaMensalistaFormaDePagamentoCreditId: this.state.cobrancaMensalistaFormaDePagamentoCreditId,
            pagamentoOnlineShipayAccessKey: this.state.pagamentoOnlineShipayAccessKey,
            pagamentoOnlineShipaySecretKey: this.state.pagamentoOnlineShipaySecretKey,
            pagamentoOnlineShipayClientId: this.state.pagamentoOnlineShipayClientId,
            habilitarCobrancaMensalistaEmissaoDeNotasTodosOsPagamentos: this.state.habilitarCobrancaMensalistaEmissaoDeNotasTodosOsPagamentos,
            habilitarCobrancaMensalistaPermitirMudarDocumento: this.state.habilitarCobrancaMensalistaPermitirMudarDocumento,
            habilitarPagamentoTicketsOnline: this.state.habilitarPagamentoTicketsOnline,
            cobrancaTicketFormaDePagamentoPixGateway: this.state.cobrancaTicketFormaDePagamentoPixGateway,
            cobrancaTicketFormaDePagamentoPixGatewayHabilitado: this.state.cobrancaTicketFormaDePagamentoPixGatewayHabilitado,
            cobrancaTicketFormaDePagamentoPixId: this.state.cobrancaTicketFormaDePagamentoPixId
        }
        await updateParameterById({ id: this.state.id, data: data })
        await saveLastUpdate()
        sendClickButton("Parametros Cobrancas", "Atualizar")
        reloadWithAlert("Parametros de cobranças atualizados com sucesso!")
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Parâmetros de cobrancas
                        </div>
                        {
                            this.state.isLoading && <Loading />
                        }
                        {
                            !this.state.isLoading && <>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={tab => this.changeTab(tab)}>
                                                <Tab eventKey="mensalista" title="Mensalista">
                                                    <div className='row mt-3'>
                                                        {
                                                            !this.state.pagamentoOnlineGatewayShipay && !this.state.pagamentoOnlineGatewaySafe2Pay ?
                                                                <div className='col-lg-12'>
                                                                    <AlertDanger message="É preciso entrar em contato com o suporte para te orientar a configurar o pagamento onliine." />
                                                                </div> :
                                                                <>
                                                                    <div className='col-lg-4'>
                                                                        <AlertSuccess message="Permita que seus mensalistas paguem as cobranças de forma online.">
                                                                            <div className="form-check form-switch mt-2">
                                                                                <input className="form-check-input" id="habilitarPagamentoMensalistasOnline" checked={this.state.habilitarPagamentoMensalistasOnline} onChange={(e) => this.setState({ habilitarPagamentoMensalistasOnline: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="habilitarPagamentoMensalistasOnline">
                                                                                    Habilitar pagamentos online de mensalista
                                                                                </label>
                                                                            </div>
                                                                        </AlertSuccess>
                                                                        {
                                                                            this.state.habilitarPagamentoMensalistasOnline && <>
                                                                                <div className="form-check form-switch my-2">
                                                                                    <input className="form-check-input" id="habilitarTarifaAposVencimento" checked={this.state.habilitarTarifaAposVencimento} onChange={(e) => this.setState({ habilitarTarifaAposVencimento: e.target.checked })} type="checkbox" />
                                                                                    <label className="form-check-label" htmlFor="habilitarTarifaAposVencimento">
                                                                                        Cobrar tarifa normalmente depois do vencimento.
                                                                                    </label>
                                                                                    {
                                                                                        this.state.habilitarTarifaAposVencimento === true &&
                                                                                        <div className="row">
                                                                                            <div className="col-lg-12 my-2">
                                                                                                <select className="form-select" value={this.state.tarifaAposVencimenoDias && this.state.tarifaAposVencimenoDias} onChange={(e) => this.setState({ tarifaAposVencimenoDias: e.target.value })}>
                                                                                                    <option value="0">No mesmo dia</option>
                                                                                                    <option value="1">Após 1 dia</option>
                                                                                                    <option value="2">Após 2 dias</option>
                                                                                                    <option value="3">Após 3 dias</option>
                                                                                                    <option value="4">Após 4 dias</option>
                                                                                                    <option value="5">Após 5 dias</option>
                                                                                                    <option value="6">Após 6 dias</option>
                                                                                                    <option value="7">Após 7 dias</option>
                                                                                                    <option value="8">Após 8 dias</option>
                                                                                                    <option value="9">Após 9 dias</option>
                                                                                                    <option value="10">Após 10 dias</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <div className="form-check form-switch my-2">
                                                                                    <input className="form-check-input" id="habilitarAcrecimoAposVencimento" checked={this.state.habilitarAcrecimoAposVencimento} onChange={(e) => this.setState({ habilitarAcrecimoAposVencimento: e.target.checked })} type="checkbox" />
                                                                                    <label className="form-check-label" htmlFor="habilitarAcrecimoAposVencimento">
                                                                                        Habilitar cobrança de acréscimo após vencimento.
                                                                                    </label>
                                                                                </div>
                                                                                {
                                                                                    this.state.habilitarAcrecimoAposVencimento === true &&
                                                                                    <div className="col-lg-12 row my-2">
                                                                                        <label className="my-2">Após </label>
                                                                                        <div className="col-lg-6">
                                                                                            <select className="form-select" value={this.state.acrecimoAposVencimentoDias && this.state.acrecimoAposVencimentoDias} onChange={(e) => this.setState({ acrecimoAposVencimentoDias: e.target.value })}>
                                                                                                <option value="1">1 dia</option>
                                                                                                <option value="2">2 dias</option>
                                                                                                <option value="3">3 dias</option>
                                                                                                <option value="4">4 dias</option>
                                                                                                <option value="5">5 dias</option>
                                                                                                <option value="6">6 dias</option>
                                                                                                <option value="7">7 dias</option>
                                                                                                <option value="8">8 dias</option>
                                                                                                <option value="9">9 dias</option>
                                                                                                <option value="10">10 dias</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <label className="my-2">de inadimplência, cobrar</label>
                                                                                        <div className="col-lg-6">
                                                                                            <input type="number" onChange={(e) => this.setState({ acrecimoAposVencimentoPorcentagem: e.target.value })} value={this.state.acrecimoAposVencimentoPorcentagem} className="form-control" />
                                                                                        </div>
                                                                                        <label className="my-2">% de acrécimo.</label>
                                                                                    </div>
                                                                                }
                                                                                <div className="form-check form-switch my-2">
                                                                                    <input className="form-check-input" id="habilitarDescontoAntesDoVencimento" checked={this.state.habilitarDescontoAntesDoVencimento} onChange={(e) => this.setState({ habilitarDescontoAntesDoVencimento: e.target.checked })} type="checkbox" />
                                                                                    <label className="form-check-label" htmlFor="habilitarDescontoAntesDoVencimento">
                                                                                        Habilitar desconto para pagamentos antes do vencimento.
                                                                                    </label>
                                                                                </div>
                                                                                {
                                                                                    this.state.habilitarDescontoAntesDoVencimento === true &&
                                                                                    <div className="col-lg-12 row my-2">
                                                                                        <label className="my-2">Até </label>
                                                                                        <div className="col-lg-6">
                                                                                            <select className="form-select" value={this.state.descontoAntesDoVencimentoDias && this.state.descontoAntesDoVencimentoDias} onChange={(e) => this.setState({ descontoAntesDoVencimentoDias: e.target.value })}>
                                                                                                <option value="1">1 dia</option>
                                                                                                <option value="2">2 dias</option>
                                                                                                <option value="3">3 dias</option>
                                                                                                <option value="4">4 dias</option>
                                                                                                <option value="5">5 dias</option>
                                                                                                <option value="6">6 dias</option>
                                                                                                <option value="7">7 dias</option>
                                                                                                <option value="8">8 dias</option>
                                                                                                <option value="9">9 dias</option>
                                                                                                <option value="10">10 dias</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <label className="my-2">de adiantamento, dar </label>
                                                                                        <div className="col-lg-6">
                                                                                            <input type="number" onChange={(e) => this.setState({ descontoAntesDoVencimentoPorcentagem: e.target.value })} value={this.state.descontoAntesDoVencimentoPorcentagem} className="form-control" />
                                                                                        </div>
                                                                                        <label className="my-2">% de desconto.</label>
                                                                                    </div>
                                                                                }
                                                                                <div className="form-check form-switch my-2">
                                                                                    <input className="form-check-input" id="habilitarMensagemInadimplente" checked={this.state.habilitarMensagemInadimplente} onChange={(e) => this.setState({ habilitarMensagemInadimplente: e.target.checked })} type="checkbox" />
                                                                                    <label className="form-check-label" htmlFor="habilitarMensagemInadimplente">
                                                                                        Imprimir mensagem no ticket aos mensalistas inadimplentes.
                                                                                    </label>
                                                                                </div>
                                                                                {
                                                                                    this.state.habilitarMensagemInadimplente === true &&
                                                                                    <div className="row">
                                                                                        <div className="col-lg-12">
                                                                                            <input placeholder="Mensagem para mensalistas inadimplentes" type="text" onChange={(e) => this.setState({ mensagemMensalistaInadimplentes: e.target.value })} value={this.state.mensagemMensalistaInadimplentes} className="form-control" />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                <div className='mt-3'>
                                                                                    <AlertSecondary tag="Nota fiscal" message="Emita notas fiscais de forma automatica ao receber os pagamentos.">
                                                                                        {
                                                                                            this.state.habilitarEmissaoNotaV2 ? <>
                                                                                                <div className="form-check form-switch my-2">
                                                                                                    <input className="form-check-input" id="habilitarCobrancaMensalistaEmissaoDeNotasTodosOsPagamentos" checked={this.state.habilitarCobrancaMensalistaEmissaoDeNotasTodosOsPagamentos} onChange={(e) => this.setState({ habilitarCobrancaMensalistaEmissaoDeNotasTodosOsPagamentos: e.target.checked })} type="checkbox" />
                                                                                                    <label className="form-check-label" htmlFor="habilitarCobrancaMensalistaEmissaoDeNotasTodosOsPagamentos">
                                                                                                        Emitir nota para todos os pagamentos.
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="form-check form-switch my-2">
                                                                                                    <input className="form-check-input" id="habilitarCobrancaMensalistaPermitirMudarDocumento" checked={this.state.habilitarCobrancaMensalistaPermitirMudarDocumento} onChange={(e) => this.setState({ habilitarCobrancaMensalistaPermitirMudarDocumento: e.target.checked })} type="checkbox" />
                                                                                                    <label className="form-check-label" htmlFor="habilitarCobrancaMensalistaPermitirMudarDocumento">
                                                                                                        Permitir que o cliente informe outro documento na emissão.
                                                                                                    </label>
                                                                                                </div>
                                                                                            </>
                                                                                                :
                                                                                                <div className='mt-2'>
                                                                                                    <a href='/nota-fiscal/configurar' className='btn btn-secondary'>Habilitar emissão de notas</a>
                                                                                                </div>
                                                                                        }
                                                                                    </AlertSecondary>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        this.state.habilitarPagamentoMensalistasOnline && <>
                                                                            <div className='col-4 col-lg-4'>
                                                                                <div className="row">
                                                                                    <AlertSecondary tag="Informação" message="Nós enviamos as cobranças via WhatsApp com o link para pagamento em Pix sem custo." />
                                                                                    <div className='col-lg-12'>
                                                                                        <label>Alertas de cobranças antes do vencimento.</label>
                                                                                        <select className="form-select" value={this.state.cobrancaMensalistaFormaAlertaAntesDoVencimento} onChange={(e) => this.setState({ cobrancaMensalistaFormaAlertaAntesDoVencimento: e.target.value })}>
                                                                                            <option value="0">Enviar a cobrança para o cliente no dia do vencimento</option>
                                                                                            <option value="1">Enviar a cobrança para o cliente diariamente 1 dia antes do vencimento</option>
                                                                                            <option value="2">Enviar a cobrança para o cliente diariamente 2 dias antes do vencimento</option>
                                                                                            <option value="3">Enviar a cobrança para o cliente diariamente 3 dias antes do vencimento</option>
                                                                                            <option value="4">Enviar a cobrança para o cliente diariamente 4 dias antes do vencimento</option>
                                                                                            <option value="5">Enviar a cobrança para o cliente diariamente 5 dias antes do vencimento</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className='col-lg-12'>
                                                                                        <label>Alertas de cobranças após o vencimento</label>
                                                                                        <select className="form-select" value={this.state.cobrancaMensalistaFormaAlertaAposVencimento} onChange={(e) => this.setState({ cobrancaMensalistaFormaAlertaAposVencimento: e.target.value })}>
                                                                                            <option value="0">Enviar a cobrança para o cliente diariamente 1 dia após do vencimento</option>
                                                                                            <option value="2">Enviar a cobrança para o cliente diariamente 2 dias após do vencimento</option>
                                                                                            <option value="3">Enviar a cobrança para o cliente diariamente 3 dias após do vencimento</option>
                                                                                            <option value="4">Enviar a cobrança para o cliente diariamente 4 dias após do vencimento</option>
                                                                                            <option value="5">Enviar a cobrança para o cliente diariamente 5 dias após do vencimento</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {
                                                                                        isAdministrador() &&
                                                                                        <div className='col-lg-12 mt-3'>
                                                                                            <button type="button" onClick={(e) => { this.setState({ isShowTemplateCharges: true }) }} className="btn btn-warning text-white">Personalizar mensagens</button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-lg-4'>
                                                                                <div className="row">
                                                                                    <AlertInfo tag="Informação" message="Os pagamentos online serão apresentados no caixa aberto e deve ter uma forma de pagamento atribuída." />
                                                                                    {
                                                                                        this.state.paymentPixMethods.length > 0 ?
                                                                                            <AlertSecondary tag="Informação" message="Formas de pagamentos">
                                                                                                <div className="form-check form-switch my-2">
                                                                                                    <input className="form-check-input" id="cobrancaMensalistaFormaDePagamentoPixGatewayHabilitado" checked={this.state.cobrancaMensalistaFormaDePagamentoPixGatewayHabilitado} onChange={(e) => this.setState({ cobrancaMensalistaFormaDePagamentoPixGatewayHabilitado: e.target.checked })} type="checkbox" />
                                                                                                    <label className="form-check-label" htmlFor="cobrancaMensalistaFormaDePagamentoPixGatewayHabilitado">
                                                                                                        Habilitar pagamento via Pix
                                                                                                    </label>
                                                                                                </div>
                                                                                                {
                                                                                                    this.state.cobrancaMensalistaFormaDePagamentoPixGatewayHabilitado &&
                                                                                                    <>
                                                                                                        <select className="form-select" value={this.state.cobrancaMensalistaFormaDePagamentoPixId} onChange={(e) => this.setState({ cobrancaMensalistaFormaDePagamentoPixId: e.target.value })}>
                                                                                                            <option value="">Selecionar</option>
                                                                                                            {
                                                                                                                this.state.paymentPixMethods.map(e => {
                                                                                                                    return <option value={e.id}>{e.tipo === e.nome ? e.nome : `${e.tipo} - ${e.nome}`}</option>
                                                                                                                })
                                                                                                            }
                                                                                                        </select>
                                                                                                    </>
                                                                                                }
                                                                                            </AlertSecondary> :
                                                                                            <AlertDanger message="É preciso ter ao menos uma forma de pagamento como Pix cadastrada." >
                                                                                                <div className='mt-2'>
                                                                                                    <a href="/cadastrar/forma-pagamento" className="btn btn-danger">Cadastrar forma de pagamento</a>
                                                                                                </div>
                                                                                            </AlertDanger>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    this.state.pagamentoOnlineGatewaySafe2Pay &&
                                                                                    <div className="row">
                                                                                        {
                                                                                            this.state.paymentCreditMethods.length > 0 ?
                                                                                                <AlertSecondary tag="Informação" message="Processadora de pagamento para cartão de crédito">
                                                                                                    <div className="form-check form-switch my-2">
                                                                                                        <input className="form-check-input" id="cobrancaMensalistaFormaDePagamentoCreditGatewayHabilitado" checked={this.state.cobrancaMensalistaFormaDePagamentoCreditGatewayHabilitado} onChange={(e) => this.setState({ cobrancaMensalistaFormaDePagamentoCreditGatewayHabilitado: e.target.checked })} type="checkbox" />
                                                                                                        <label className="form-check-label" htmlFor="cobrancaMensalistaFormaDePagamentoCreditGatewayHabilitado">
                                                                                                            Habilitar pagamento via cartão de crédito
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    {
                                                                                                        this.state.cobrancaMensalistaFormaDePagamentoCreditGatewayHabilitado &&
                                                                                                        <>
                                                                                                            <select className="form-select mt-2" value={this.state.cobrancaMensalistaFormaDePagamentoCreditGateway} onChange={(e) => this.setState({ cobrancaMensalistaFormaDePagamentoCreditGateway: e.target.value })}>
                                                                                                                <option value="">Selecionar</option>
                                                                                                                {
                                                                                                                    this.state.pagamentoOnlineGatewaySafe2Pay && <option value="Safe2Pay">Safe2Pay</option>
                                                                                                                }
                                                                                                            </select>
                                                                                                            <label>Forma de pagamento</label>
                                                                                                            <select className="form-select" value={this.state.cobrancaMensalistaFormaDePagamentoCreditId} onChange={(e) => this.setState({ cobrancaMensalistaFormaDePagamentoCreditId: e.target.value })}>
                                                                                                                <option value="">Selecionar</option>
                                                                                                                {
                                                                                                                    this.state.paymentCreditMethods.map(e => {
                                                                                                                        return <option value={e.id}>{e.tipo === e.nome ? e.nome : `${e.tipo} - ${e.nome}`}</option>
                                                                                                                    })
                                                                                                                }
                                                                                                            </select>
                                                                                                        </>
                                                                                                    }
                                                                                                </AlertSecondary> :
                                                                                                <AlertDanger message="É preciso ter ao menos uma forma de pagamento como Crédito cadastrada." >
                                                                                                    <div className='mt-2'>
                                                                                                        <a href="/cadastrar/forma-pagamento" className="btn btn-danger">Cadastrar forma de pagamento</a>
                                                                                                    </div>
                                                                                                </AlertDanger>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="ticket" title="Ticket">
                                                    <div className='row mt-3'>
                                                        {
                                                            !this.state.pagamentoOnlineGatewayShipay && !this.state.pagamentoOnlineGatewaySafe2Pay ?
                                                                <div className='col-lg-12'>
                                                                    <AlertDanger message="É preciso entrar em contato com o suporte para te orientar a configurar o pagamento onliine." />
                                                                </div> :
                                                                <>
                                                                    <div className='col-lg-4'>
                                                                        <AlertSuccess message="Permita que seus clientes paguem os tickets avulsos de forma online.">
                                                                            <div className="form-check form-switch mt-2">
                                                                                <input className="form-check-input" id="habilitarPagamentoTicketsOnline" checked={this.state.habilitarPagamentoTicketsOnline} onChange={(e) => this.setState({ habilitarPagamentoTicketsOnline: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="habilitarPagamentoTicketsOnline">
                                                                                    Habilitar pagamentos online de ticket
                                                                                </label>
                                                                            </div>
                                                                        </AlertSuccess>
                                                                        {
                                                                            this.state.habilitarPagamentoTicketsOnline && <>
                                                                                <div className="row">
                                                                                    <AlertInfo tag="Informação" message="Os pagamentos online serão apresentados no caixa aberto e deve ter uma forma de pagamento atribuída." />
                                                                                    {
                                                                                        this.state.paymentPixMethods.length > 0 ?
                                                                                            <AlertSecondary tag="Informação" message="Formas de pagamentos">
                                                                                                <div className="form-check form-switch my-2">
                                                                                                    <input className="form-check-input" id="cobrancaTicketFormaDePagamentoPixGatewayHabilitado" checked={this.state.cobrancaTicketFormaDePagamentoPixGatewayHabilitado} onChange={(e) => this.setState({ cobrancaTicketFormaDePagamentoPixGatewayHabilitado: e.target.checked })} type="checkbox" />
                                                                                                    <label className="form-check-label" htmlFor="cobrancaTicketFormaDePagamentoPixGatewayHabilitado">
                                                                                                        Habilitar pagamento via Pix
                                                                                                    </label>
                                                                                                </div>
                                                                                                {
                                                                                                    this.state.cobrancaTicketFormaDePagamentoPixGatewayHabilitado &&
                                                                                                    <>
                                                                                                        <select className="form-select" value={this.state.cobrancaTicketFormaDePagamentoPixId} onChange={(e) => this.setState({ cobrancaTicketFormaDePagamentoPixId: e.target.value })}>
                                                                                                            <option value="">Selecionar</option>
                                                                                                            {
                                                                                                                this.state.paymentPixMethods.map(e => {
                                                                                                                    return <option value={e.id}>{e.tipo === e.nome ? e.nome : `${e.tipo} - ${e.nome}`}</option>
                                                                                                                })
                                                                                                            }
                                                                                                        </select>
                                                                                                    </>
                                                                                                }
                                                                                            </AlertSecondary> :
                                                                                            <AlertDanger message="É preciso ter ao menos uma forma de pagamento como Pix cadastrada." >
                                                                                                <div className='mt-2'>
                                                                                                    <a href="/cadastrar/forma-pagamento" className="btn btn-danger">Cadastrar forma de pagamento</a>
                                                                                                </div>
                                                                                            </AlertDanger>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </Tab>
                                                {
                                                    isAdministrador() &&
                                                    <Tab eventKey="gateway" title="Gateway">
                                                        <div className='row mt-3'>
                                                            <div className='row'>
                                                                <div className="col-lg-3">
                                                                    <AlertDanger tag="Visivel apenas para administradores" message="Habilite os gateways que podem procesar os pagamentos online." />
                                                                </div>
                                                                <div className='col-lg-4'>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="pagamentoOnlineGatewayShipay" checked={this.state.pagamentoOnlineGatewayShipay} onChange={(e) => this.setState({ pagamentoOnlineGatewayShipay: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="pagamentoOnlineGatewayShipay">
                                                                            Shipay
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.pagamentoOnlineGatewayShipay &&
                                                                        <>
                                                                            <label>Access Key</label>
                                                                            <input type="text" onChange={(e) => this.setState({ pagamentoOnlineShipayAccessKey: e.target.value })} value={this.state.pagamentoOnlineShipayAccessKey} className="form-control" />
                                                                            <label>Secret Key</label>
                                                                            <input type="text" onChange={(e) => this.setState({ pagamentoOnlineShipaySecretKey: e.target.value })} value={this.state.pagamentoOnlineShipaySecretKey} className="form-control" />
                                                                            <label>Client Id</label>
                                                                            <input type="text" onChange={(e) => this.setState({ pagamentoOnlineShipayClientId: e.target.value })} value={this.state.pagamentoOnlineShipayClientId} className="form-control" />
                                                                        </>
                                                                    }
                                                                    <div className='col-lg-12'>
                                                                        <label>Gateway de pagamento via Pix para mensalistas</label>
                                                                        <select className="form-select mt-2" value={this.state.cobrancaMensalistaFormaDePagamentoPixGateway} onChange={(e) => this.setState({ cobrancaMensalistaFormaDePagamentoPixGateway: e.target.value })}>
                                                                            <option value="">Selecionar</option>
                                                                            {
                                                                                this.state.pagamentoOnlineGatewaySafe2Pay && <option value="Safe2Pay">Safe2Pay</option>
                                                                            }
                                                                            {
                                                                                this.state.pagamentoOnlineGatewayShipay && <option value="Shipay">Shipay</option>
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <label>Gateway de pagamento via Pix para tickets</label>
                                                                        <select className="form-select mt-2" value={this.state.cobrancaTicketFormaDePagamentoPixGateway} onChange={(e) => this.setState({ cobrancaTicketFormaDePagamentoPixGateway: e.target.value })}>
                                                                            <option value="">Selecionar</option>
                                                                            {
                                                                                this.state.pagamentoOnlineGatewaySafe2Pay && <option value="Safe2Pay">Safe2Pay</option>
                                                                            }
                                                                            {
                                                                                this.state.pagamentoOnlineGatewayShipay && <option value="Shipay">Shipay</option>
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-lg-4'>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="pagamentoOnlineGatewaySafe2Pay" checked={this.state.pagamentoOnlineGatewaySafe2Pay} onChange={(e) => this.setState({ pagamentoOnlineGatewaySafe2Pay: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="pagamentoOnlineGatewaySafe2Pay">
                                                                            Safe2Pay
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        this.state.pagamentoOnlineGatewaySafe2Pay &&
                                                                        <div>
                                                                            <label>Token de autenticação</label>
                                                                            <input type="text" onChange={(e) => this.setState({ pagamentoOnlineGatewayToken: e.target.value })} value={this.state.pagamentoOnlineGatewayToken} className="form-control" />
                                                                        </div>
                                                                    }
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                }
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <button type="button" onClick={this.update} className="btn btn-primary">Atualizar</button>
                                </div>
                                <Dialog onClose={(e) => this.setState({ isShowTemplateCharges: false })} fullWidth maxWidth={"lg"} open={this.state.isShowTemplateCharges}>
                                    <MuiDialogTitle className='text-center'>
                                        Mensagens de cobranças
                                    </MuiDialogTitle>
                                    <MuiDialogContent>
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-lg-3'>
                                                    <label>Mensagem enviada com o link de cadastro</label>
                                                    <textarea placeholder={this.state.cobrancaMensalistaTemplateNoCadastroPlaceholder} className='form-control' onChange={(e) => this.setState({ cobrancaMensalistaTemplateNoCadastro: e.target.value })} rows={8}>{this.state.cobrancaMensalistaTemplateNoCadastro}</textarea>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <label>Mensagem enviada antes do vencimento</label>
                                                    <textarea placeholder={this.state.cobrancaMensalistaTemplateAntesDoVencimentoPlaceholder} className='form-control' onChange={(e) => this.setState({ cobrancaMensalistaTemplateAntesDoVencimento: e.target.value })} rows={8}>{this.state.cobrancaMensalistaTemplateAntesDoVencimento}</textarea>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <label>Mensagem enviada no dia do vencimento</label>
                                                    <textarea placeholder={this.state.cobrancaMensalistaTemplateNoPagamentoPlaceholder} className='form-control' onChange={(e) => this.setState({ cobrancaMensalistaTemplateNoPagamento: e.target.value })} rows={8}>{this.state.cobrancaMensalistaTemplateNoPagamento}</textarea>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-lg-3'>
                                                    <label>Mensagem enviada após o vencimento</label>
                                                    <textarea placeholder={this.state.cobrancaMensalistaTemplateAposVencimentoPlaceholder} className='form-control' onChange={(e) => this.setState({ cobrancaMensalistaTemplateAposVencimento: e.target.value })} rows={8}>{this.state.cobrancaMensalistaTemplateAposVencimento}</textarea>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <label>Resposta automatica caso um cliente mande mensagem</label>
                                                    <textarea placeholder={this.state.templateRespostaAutomaticaWhatsApp} className='form-control' onChange={(e) => this.setState({ templateRespostaAutomaticaWhatsApp: e.target.value })} rows={8}>{this.state.templateRespostaAutomaticaWhatsApp}</textarea>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <AlertInfo>
                                                    É importante informar os dados do seu estabelecimento para que o cliente saiba sobre a cobrança e a URL da cobrança pode ser enviada na mensagem.
                                                    <p />
                                                    <div className='row'>
                                                        <div className='col-lg-4'>
                                                            <strong>Variaveis:</strong><br />
                                                            PARK_NAME: Nome do estabelecimento<br />
                                                            PARK_PHONE: Telefone do estabelecimento<br />
                                                            CLIENT_NAME: Primeiro nome do mensalista<br />
                                                            URL_DETAIL: Site para acessar a cobrança<br />
                                                            DUE_DATE: Data de vencimento<br />
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <strong>Exemplo:</strong><br />
                                                            Olá CLIENT_NAME, falamos em nome do PARK_NAME, a sua mensalidade vence em breve,
                                                            você consegue fazer o pagamento de forma online tocando no link abaixo:<p />

                                                            URL_DETAIL<p />

                                                            Você precisa pagar até DUE_DATE para evitar multas.<p />

                                                            Caso tenha alguma dúvida, entre em contato agora mesmo PARK_PHONE
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <strong>Resultado:</strong><br />
                                                            Olá Carlos, falamos em nome do {this.state.park.nome}, a sua mensalidade vence em breve,
                                                            você consegue fazer o pagamento de forma online tocando no link abaixo:<p />

                                                            <strong><a target='_blank' href={`${getRevenda()?.siteTicket}/bem-vindo?id=${this.state.park.id}`}>{getRevenda()?.siteTicket}/bem-vindo?id={this.state.park.id}</a></strong>
                                                            <p />

                                                            Você precisa pagar até {moment().format("DD [do] MM")} para evitar multas.<p />

                                                            Caso tenha alguma dúvida, entre em contato agora mesmo no celular {this.state.park.celular}
                                                        </div>
                                                    </div>
                                                </AlertInfo>
                                            </div>
                                        </div>
                                    </MuiDialogContent>
                                    <MuiDialogActions>
                                        <div className='btn-group m-3'>
                                            <button onClick={(e) => this.setState({ isShowTemplateCharges: false })} className='btn btn-success'>Concluir</button>
                                        </div>
                                    </MuiDialogActions>
                                </Dialog>
                            </>
                        }
                    </div>
                </NavBar >
            </>
        )
    }
}

export { ListarParametros, ListarParametrosCobrancas }